import React from 'react'
import { CouponBoxTableType } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'

type UnapplyCouponBoxTableItemProps = {
  tableType: CouponBoxTableType
  isSelected: boolean
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => React.MouseEventHandler<HTMLInputElement>
}

/**
 * See the component CouponBoxTableItem
 */
export const CouponBoxTableItemUnapply = ({
  tableType,
  isSelected,
  onCancelCoupon,
}: UnapplyCouponBoxTableItemProps): JSX.Element => {
  return (
    <li className="list-item">
      <div className="box__title">
        <div className="box__form-radio">
          <input
            type="radio"
            className="form__radio"
            checked={isSelected}
            onClick={onCancelCoupon(tableType)}
            id={`unapply_coupon_radio-${tableType}`}
            data-montelena-acode={areaCodes.COUPON_BOX_UNAPPLY}
          />
          <label
            htmlFor={`unapply_coupon_radio-${tableType}`}
            className="form__label form__cancel sprite__coupon--before"
          >
            적용안함
          </label>
        </div>
      </div>
    </li>
  )
}
