import React from 'react'
import CartUnitAddition from '~/cart/gmarket/ko/common/components/CartItemList/CartUnitAddition'
import CartUnitDiscountNudge from '~/cart/gmarket/ko/common/components/CartItemList/CartUnitDiscountNudge'
import CartUnitOption from '~/cart/gmarket/ko/common/components/CartItemList/CartUnitOption'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import ArrivalEstimateContainer from '~/cart/gmarket/ko/common/containers/CartItemList/ArrivalEstimateContainer'
import CartUnitQtyControlContainer from '~/cart/gmarket/ko/common/containers/CartItemList/CartUnitQtyControlContainer'
import { CartUnit, CartUnitPriceType } from '~/cart/modules/cart/types'
import { ShippingPolicy } from '~/cart/modules/shipping/types'
import { BuyUnavailableInfo } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import tenantConstants from '~/data/checkout-constants'
import { ElementIds } from '~/data/consts'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { currencyAmount, formatString } from '~/lib/formatter'
import { DeliveryCouponErrorType } from '~/types/enums'

const CartUnitComponent = ({
  cartUnit,
  idx,
  cartUnitPrice,
  shippingPolicy,
  isSmileClub,
  isMobile,
  isShippingUnavailable,
  isOverseaShipping,
  deliveryCouponErrorType,
  isCouponApplied,
  buyUnavailableInfo,
  onClickRemoveButton,
  isFirstDuplicatesCartUnit,
  isCurrentShippingUnavailable,
  isBundleDiscountCartUnit,
  bundleDiscountApplySuccess,
  couponBoxButton,
  children,
}: {
  cartUnit: CartUnit
  idx: number
  cartUnitPrice: CartUnitPriceType
  shippingPolicy?: ShippingPolicy & {
    isSpecialShipping: boolean
    shippingMethodText?: string
  }
  isSmileClub: boolean
  isMobile: boolean
  isShippingUnavailable: boolean
  isOverseaShipping: boolean
  deliveryCouponErrorType?: DeliveryCouponErrorType
  isCouponApplied: boolean
  buyUnavailableInfo?: BuyUnavailableInfo
  onClickRemoveButton: React.MouseEventHandler<HTMLElement>
  isFirstDuplicatesCartUnit: boolean
  isCurrentShippingUnavailable: boolean
  isBundleDiscountCartUnit: boolean
  bundleDiscountApplySuccess: boolean
  couponBoxButton: JSX.Element
  children?: React.ReactNode
}): JSX.Element => {
  return (
    <dd
      className="unit--item_desc"
      id={ElementIds.CartUnit + cartUnit.cartUnitId}
    >
      <CartUnitDiscountNudge
        idx={idx}
        isSmileClub={isSmileClub}
        exhibitExtraDiscount={cartUnit.exhibitExtraDiscount}
      />

      <div className="section item_title">
        <CustomAnchor
          href={formatString(domains.VIP, cartUnit.item.itemNo)}
          data-montelena-acode={areaCodes.ITEM_NAME}
          data-montelena-goodscode={cartUnit.item.itemNo}
        >
          <strong className="for_a11y">상품명: </strong>
          <span className="item_name" id={cartUnit.item.itemNo}>
            {cartUnit.item.itemName}
          </span>
        </CustomAnchor>
        {idx === 0 &&
          cartUnit.item.itemPurchaseBenefitList &&
          cartUnit.item.itemPurchaseBenefitList.length > 0 && (
            <div className="box__tag">
              <span className="for-a11y">행사정보</span>
              {cartUnit.item.itemPurchaseBenefitList.map((benefit, idx) => {
                if (benefit.benefitType === 'Extra') {
                  return (
                    <span
                      key={`purchase_benefit_${idx}`}
                      className="sprite__cart--after text__tag"
                    >
                      {benefit.benefitValue}
                    </span>
                  )
                }
              })}
            </div>
          )}
        {isMobile && cartUnit.item.isInstall && idx === 0 && (
          <div className="box__install">
            <span className="text__install">예약설치</span>
          </div>
        )}
        {!isMobile &&
          cartUnit.item.isInstall && ( // 왜 모바일이랑 다르게 노출하는거지? 일단은 나눠 놓자...
            <div className="box__install">
              <span className="text__install">예약설치</span>
            </div>
          )}
        {cartUnit.cartUnitType === 'SmileFresh' && idx === 0 ? (
          <span className="box__delivery-info">
            <span className="text__smilefresh sprite__cart">
              <span className="for-a11y">스마일프레시</span>
            </span>
          </span>
        ) : (
          !buyUnavailableInfo &&
          !isOverseaShipping &&
          !isShippingUnavailable &&
          idx === 0 && (
            <ArrivalEstimateContainer itemNo={cartUnit.item.itemNo} />
          )
        )}
      </div>

      {cartUnit.item.options && cartUnit.item.options.length > 0 && (
        <div className="section item_option">
          <strong className="for_a11y">옵션선택 정보</strong>
          <ul>
            {cartUnit.item.options.map((option) => {
              return (
                <CartUnitOption
                  key={`cart_unit_option_${cartUnit.cartUnitId}_${option.optionNo}`}
                  option={option}
                />
              )
            })}
          </ul>
        </div>
      )}
      {cartUnit.item.additions && cartUnit.item.additions.length > 0 && (
        <div className="section item_add">
          <strong className="for_a11y">추가구성 정보</strong>
          <ul>
            {cartUnit.item.additions.map((addition) => {
              return (
                <CartUnitAddition
                  key={`cart_unit_addition_${cartUnit.cartUnitId}_${addition.additionNo}`}
                  addition={addition}
                />
              )
            })}
          </ul>
        </div>
      )}
      {!buyUnavailableInfo &&
        isShippingUnavailable &&
        tenantConstants.IsOverseaShippingAvailable && (
          <div className="section item_notice">
            <strong>선택하신 국가로 배송이 불가한 상품입니다.</strong>
          </div>
        )}
      {deliveryCouponErrorType === 'FREE_SHIPPING' && (
        <div className="section item_notice">
          <strong>
            결제할 묶음배송비가 0원인 경우 무료배송쿠폰은 사용할 수 없습니다.
          </strong>
        </div>
      )}
      {deliveryCouponErrorType === 'SPECIAL_SHIPPING' && (
        <div className="section item_notice">
          <strong>
            착불/퀵서비스/방문수령/직접배송 상품인 경우 무료배송쿠폰은 사용할 수
            없습니다.
          </strong>
        </div>
      )}
      {!buyUnavailableInfo &&
        isShippingUnavailable &&
        !tenantConstants.IsOverseaShippingAvailable && (
          <div className="section item_notice" style={{ marginBottom: '10px' }}>
            <strong
              style={{
                position: 'relative',
                color: '#f83030',
                height: '30px',
                fontSize: '12px',
                lineHeight: '13px',
                margin: '4px 0',
                fontWeight: 'normal',
              }}
            >
              국내배송불가 상품입니다.
            </strong>
          </div>
        )}
      {buyUnavailableInfo && ( // TODO
        <>
          {buyUnavailableInfo.unableOrderType ===
          'ONLY_SMILE_FRESH_DAWN_BRANCH' ? (
            <div className="section item_smiefresh-state">
              <p className="box__state">
                <span className="text__smiefresh-delivery icon-dawn sprite__cart--before">
                  새벽배송으로만 주문할 수 있어요
                </span>
              </p>
            </div>
          ) : buyUnavailableInfo.unableOrderType ===
            'ONLY_SMILE_FRESH_RESERVE_BRANCH' ? (
            <div className="section item_smiefresh-state">
              <p className="box__state">
                <span className="text__smiefresh-delivery icon-today sprite__cart--before">
                  낮밤배송으로만 주문할 수 있어요
                </span>
              </p>
            </div>
          ) : buyUnavailableInfo.unableOrderType === 'NO_SMILE_FRESH_BRANCH' ||
            buyUnavailableInfo.unableOrderType ===
              'SMILE_FRESH_BRANCH_OUT_OF_STOCK' ? (
            <div className="section item_smiefresh-state">
              <p className="box__state">
                해당 주소에서 주문할 수 없는 상품이에요
              </p>
            </div>
          ) : (
            <div className="section item_soldout">
              <strong>{buyUnavailableInfo.defaultMessage}</strong>
              <button
                type="button"
                className="btn_del"
                data-montelena-acode={areaCodes.DELETE_UNAVAILABLE_CARTUNIT}
                onClick={onClickRemoveButton}
              >
                삭제
              </button>
            </div>
          )}
        </>
      )}
      {!(isMobile && buyUnavailableInfo) && (
        <div className="section__wrapper">
          <div className="section__item--left">
            {!buyUnavailableInfo && (
              <CartUnitQtyControlContainer cartUnit={cartUnit} />
            )}

            {!buyUnavailableInfo && couponBoxButton}
          </div>

          <div className="section__item--right">
            {!buyUnavailableInfo && (
              <>
                {isBundleDiscountCartUnit && bundleDiscountApplySuccess ? (
                  <div className="section item_price item_discount-price">
                    <strong className="for_a11y">상품 금액 : </strong>
                    {shippingPolicy && shippingPolicy.shippingMethodText && (
                      <span
                        className={`price_desc ${
                          shippingPolicy.shippingMethodType === 'QuickService'
                            ? 'quick_delivery'
                            : ''
                        }`}
                      >
                        {shippingPolicy.shippingMethodText}
                      </span>
                    )}
                    <FormatPrice
                      krwAmount={
                        cartUnitPrice.cartUnitPriceWithoutUnitCouponPrice
                      }
                    />
                    <span
                      className={`box__sale-amount ${
                        isCouponApplied ? 'box__sale-amount--coupon' : ''
                      }`}
                    >
                      <strong className="text__value">
                        {currencyAmount(Math.abs(cartUnitPrice.cartUnitPrice))}
                      </strong>
                      <span className="text__unit">원</span>
                    </span>
                  </div>
                ) : (
                  <div
                    className={`section item_price ${
                      isCouponApplied ? 'item_discount-price' : ''
                    }`}
                  >
                    <strong className="for_a11y">상품 금액 : </strong>
                    {shippingPolicy && shippingPolicy.shippingMethodText && (
                      <span
                        className={`price_desc ${
                          shippingPolicy.shippingMethodType === 'QuickService'
                            ? 'quick_delivery'
                            : ''
                        }`}
                      >
                        {shippingPolicy.shippingMethodText}
                      </span>
                    )}
                    <FormatPrice
                      krwAmount={
                        cartUnitPrice.cartUnitPriceWithoutUnitCouponPrice
                      }
                    />
                    {isCouponApplied && (
                      <span className="box__sale-amount">
                        <strong className="text__value">
                          {currencyAmount(
                            Math.abs(cartUnitPrice.cartUnitPrice),
                          )}
                        </strong>
                        <span className="text__unit">원</span>
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
            <div className="section item_del">
              <button
                className="btn_del sprite__cart"
                onClick={onClickRemoveButton}
                data-montelena-acode={
                  !buyUnavailableInfo
                    ? areaCodes.DELETE_CARTUNIT
                    : areaCodes.DELETE_UNAVAILABLE_CARTUNIT
                }
              >
                <i className="icon sprite__cart btn_cart_item_del">
                  <span className="for_a11y">상품 삭제</span>
                </i>
              </button>
              {!buyUnavailableInfo &&
                cartUnit.discounts &&
                cartUnitPrice.cartUnitPrice == 0 && (
                  <div className="box__tooltip">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <p className="text__guide">
                      &apos;0원&apos; 상품을 삭제해 주세요! 😥
                    </p>
                  </div>
                )}
              {isOverseaShipping &&
                isFirstDuplicatesCartUnit &&
                !isCurrentShippingUnavailable && (
                  <div className="box__tooltip oversea-delivery">
                    <p className="text__guide">
                      <strong className="text__emphasis">
                        해외배송비 절약!
                      </strong>{' '}
                      같은 상품은 삭제 후 수량을 추가하세요.
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {children}
    </dd>
  )
}

export default CartUnitComponent
