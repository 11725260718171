import { includes, isEmpty } from 'lodash'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'
import CouponBoxTableItem from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxTableItem'
import { CouponBoxTableItemUnapply } from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxTableItemUnapply'
import FormatPrice from '~/cart/gmarket/ko/common/components/FormatPrice'
import {
  CouponBoxTableItemViewData,
  CouponBoxTableType,
  CouponBoxTableViewData,
} from '~/cart/modules/types'
import { UnapplyCouponDummyIssueNo } from '~/data/consts'
import { formatAsDom } from '~/lib/formatter'

type CouponBoxTableProps = {
  isMobile?: boolean
  viewData: CouponBoxTableViewData
  selectedCouponIssueNo: number
  onChangeCoupon: (
    key: CouponBoxTableType,
    couponIssueNo: number,
    isAppliedOnAnother?: boolean,
  ) => React.ChangeEventHandler<HTMLInputElement>
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => React.MouseEventHandler<HTMLInputElement>
  onClickJoinSmileClub: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

type CouponExpandViewData = {
  policyNo: number
  headIssueNo: number
  tailIssueNos: number[]
}

const getCouponInfoTableAlias = (type: CouponBoxTableType): string => {
  switch (type) {
    case CouponBoxTableType.Buyer:
      return 'G마켓할인'
    case CouponBoxTableType.Seller:
      return '판매자할인'
    case CouponBoxTableType.Super:
      return '중복할인'
    case CouponBoxTableType.Bundle:
      return '더 사면 할인'
    default:
      return ''
  }
}

const getNoticeText = (type: CouponBoxTableType): string => {
  switch (type) {
    case 'Seller':
      return '적용된 할인이 없습니다.'
    case 'Super':
      return '적용 가능한 쿠폰이 없습니다.'
    default:
      return '적용 가능한 쿠폰이 없습니다.'
  }
}

const isItemDiscount = (
  type: CouponBoxTableType,
  coupon: CouponBoxTableItemViewData,
): boolean => type === CouponBoxTableType.Buyer && (coupon.isDiscount || false)

const CouponBoxTable = ({
  isMobile,
  viewData,
  selectedCouponIssueNo,
  onChangeCoupon,
  onCancelCoupon,
  onClickJoinSmileClub,
}: CouponBoxTableProps): JSX.Element => {
  const [expandViewCouponPolicyNos, setExpandViewCouponPolicyNos] = useState<
    number[]
  >([])

  const availableCoupons = viewData.coupons.filter(
    (coupon) => !coupon.isNotUseCoupon,
  )
  if (
    availableCoupons.length === 0 &&
    viewData.type === CouponBoxTableType.Bundle
  ) {
    return <></>
  }

  const hasItemDiscount =
    viewData.type == CouponBoxTableType.Buyer &&
    availableCoupons.some((coupon) => coupon.isDiscount)
  const hasPcsDiscount =
    viewData.type == CouponBoxTableType.Buyer &&
    availableCoupons.some(
      (coupon) => coupon.isFakeCoupon && coupon.fakeCouponType == 'PCS',
    )
  const hasOnlyItemDiscount = hasItemDiscount && availableCoupons.length === 1
  const hasOnlyPcsDiscount = hasPcsDiscount && availableCoupons.length === 1
  const isShowUnapplyButton =
    (viewData.type == CouponBoxTableType.Buyer ||
      viewData.type == CouponBoxTableType.Super) &&
    !hasOnlyItemDiscount &&
    !hasOnlyPcsDiscount

  const couponsExpandViewData: CouponExpandViewData[] = availableCoupons
    .reduce((acc: CouponExpandViewData[], cur: CouponBoxTableItemViewData) => {
      const { policyNo, issueNo } = cur
      if (!policyNo || !issueNo) return acc

      // 데이터 중 첫 번째로 발견되는 쿠폰을 head(대표)로 지정 (getCouponBox 응답이 이미 정렬되어 있음을 전제로 함)
      const found = acc.find(
        (x: CouponExpandViewData) => x.policyNo === policyNo,
      )
      if (found) found.tailIssueNos.push(issueNo) // 이미 있으면 tail 에 추가
      else
        acc.push({
          policyNo,
          headIssueNo: issueNo,
          tailIssueNos: [],
        })

      return acc
    }, [])
    .filter((x: CouponExpandViewData) => x.tailIssueNos.length > 0) // tail(동일정책쿠폰)이 있는 쿠폰들만 expandView

  const onClickExpandButton =
    (couponPolicyNo?: number): React.MouseEventHandler<HTMLElement> =>
    (): void => {
      if (couponPolicyNo) {
        setExpandViewCouponPolicyNos(
          expandViewCouponPolicyNos.includes(couponPolicyNo)
            ? expandViewCouponPolicyNos.filter((x) => x !== couponPolicyNo)
            : [...expandViewCouponPolicyNos, couponPolicyNo],
        )
      }
    }

  if (viewData.isIncludeCoupon) {
    return (
      <div className="box__content">
        <div className={`${isMobile ? '' : 'box__title'}`}>
          <h3 className="text__title">
            {formatAsDom(getCouponInfoTableAlias(viewData.type))}
          </h3>
        </div>
        <div className={`${isMobile ? '' : 'box__information'}`}>
          <ul className="list__coupon">
            {availableCoupons.length > 0 ? (
              viewData.coupons.map((coupon, idx) => {
                const expandViewData = couponsExpandViewData.find(
                  (x) => x.policyNo === coupon.policyNo,
                )
                return (
                  <CouponBoxTableItem
                    tableType={viewData.type}
                    key={idx}
                    id={`${viewData.type}-${coupon.issueNo}`}
                    viewData={coupon}
                    isSelected={selectedCouponIssueNo === coupon.issueNo}
                    isSelectedAuto={
                      // '적용안함' 선택했지만, 아이템 할인이 있으면 자동 적용
                      selectedCouponIssueNo === UnapplyCouponDummyIssueNo &&
                      isItemDiscount(viewData.type, coupon)
                    }
                    isExpandView={!isEmpty(expandViewData)}
                    isExpandHead={
                      expandViewData?.headIssueNo === coupon.issueNo
                    }
                    isExpanded={includes(
                      expandViewCouponPolicyNos,
                      coupon.policyNo,
                    )}
                    onClickExpandButton={onClickExpandButton}
                    onChangeCoupon={onChangeCoupon}
                    onClickJoinSmileClub={onClickJoinSmileClub}
                  />
                )
              })
            ) : (
              <li className="coupon-unit coupon-empty">
                {getNoticeText(viewData.type)}
              </li>
            )}
            {isShowUnapplyButton && (
              <CouponBoxTableItemUnapply
                tableType={viewData.type}
                isSelected={selectedCouponIssueNo === UnapplyCouponDummyIssueNo}
                onCancelCoupon={onCancelCoupon}
              />
            )}
          </ul>
        </div>
      </div>
    )
  } else {
    return (
      <div className="box__content box__content--item-sale">
        <div className="box__title">
          <h3 className="text__title">
            {formatAsDom(getCouponInfoTableAlias(viewData.type))}
          </h3>
        </div>
        <div className="box__information">
          <ul className="coupon-list">
            {viewData.coupons &&
              viewData.coupons.map((couponView) => {
                return (
                  <li className="text__price" key={couponView.issueNo}>
                    <FormatPrice krwAmount={couponView.price} />
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }
}

export default React.memo(CouponBoxTable, isEqual)
