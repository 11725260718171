import { chain, every, groupBy, isEqual, sumBy, uniq } from 'lodash'
import { combineReducers } from 'redux'
import {
  CartUnit,
  CartUnitPriceType,
  CartUnitSummaryInfoType,
  ExhibitExtraDiscountList,
  ItemGroupView,
  SellerGroupView,
  ShippingGroupView,
  UnableOrderType,
} from '~/cart/modules/cart/types'
import cashback, * as fromCashback from '~/cart/modules/cashback/reducer'
import expressShop from '~/cart/modules/express-shop/reducer'
import groupCoupon, * as fromGroupCoupon from '~/cart/modules/group-coupon/reducer'
import {
  GroupCoupon,
  GroupCouponState,
} from '~/cart/modules/group-coupon/types'
import {
  OverseaShippingInfo,
  ShippingPolicyEx,
} from '~/cart/modules/shipping/types'
import smileFresh from '~/cart/modules/smile-fresh/reducer'
import unitCoupon, * as fromUnitCoupon from '~/cart/modules/unit-coupon/reducer'
import {
  RecommendedUnitCouponNo,
  UnitCoupon,
} from '~/cart/modules/unit-coupon/types'
import tenantConstants from '~/data/checkout-constants'
import {
  ItemDiscountCouponDummyIssueNo,
  NotDownloadedCouponIssueNo,
  NotUseCouponDummyIssueNo,
  PcsFakeCouponIssueNo,
} from '~/data/consts'
import { __ } from '~/lib/i18n'
import { DeliveryCouponErrorType } from '~/types/enums'
import buyer from './buyer/reducer'
import * as fromCart from './cart/reducer'
import cart, { getCartUnitById } from './cart/reducer'
import exchange from './exchange/reducer'
import fundingDiscount, * as fromFundingDiscount from './funding-discount/reducer'
import recommend, * as fromRecommend from './recommend/reducer'
import * as fromShipping from './shipping/reducer'
import shipping, {
  getCartUnitOverseaShippingCostAndWeight,
} from './shipping/reducer'
import {
  BuyUnavailableInfo,
  CouponBoxTableItemViewData,
  CouponBoxTableType,
  CouponBoxTableViewData,
  ItemGroupSummaryType,
  OverseaShippingFeeRequest,
  SellerGroupSummaryType,
  ShippingGroupSummaryType,
  SummaryType,
  TotalCashbackSummaryType,
} from './types'
import view from './view/reducer'

const rootReducer = combineReducers({
  cart,
  buyer,
  shipping,
  view,
  expressShop,
  unitCoupon,
  groupCoupon,
  cashback,
  fundingDiscount,
  exchange,
  recommend,
  smileFresh,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>

const _asGroupCouponBoxTableItemViewData =
  (state: RootState, cartUnitIdList: number[]) =>
  (coupon: GroupCoupon): CouponBoxTableItemViewData => {
    const appliedCartUnitIdList =
      fromGroupCoupon.getGroupCouponAppliedCartUnitIdList(
        state.groupCoupon,
        coupon.couponIssueNo,
      )

    const isApplied = isEqual(cartUnitIdList, appliedCartUnitIdList)

    const appliedOnAnotherCoupon =
      appliedCartUnitIdList &&
      fromGroupCoupon
        .getAppliedGroupCouponList(state.groupCoupon, appliedCartUnitIdList)
        .find((x) => x.couponIssueNo === coupon.couponIssueNo)

    const isAppliedOnAnother = !!appliedOnAnotherCoupon && !isApplied
    const isAppliedOnAnotherPrice =
      appliedOnAnotherCoupon && appliedOnAnotherCoupon.couponPrice

    return {
      isDiscount: false,
      issueNo: coupon.couponIssueNo,
      description: coupon.couponName,
      price: coupon.couponPrice,
      expiredDate: coupon.expiredDate,
      possiblePrice: coupon.possiblePrice,
      limitPrice: coupon.limitPrice,
      isSmileclubCoupon: coupon.isSmileclubCoupon,
      isBizCoupon: coupon.isBizCoupon,
      isMobileCoupon: coupon.isMobileCoupon,
      isAppCoupon: coupon.isAppCoupon,
      isDeliveryCoupon: coupon.isDeliveryCoupon,
      isApplied,
      isAppliedOnAnother,
      appliedOnAnotherPrice: isAppliedOnAnotherPrice,
      discountUnitType: coupon.discountUnitType,
      paymentCouponName: coupon.paymentCouponName,
      policyNo: coupon.couponPolicyNo,
      eventNo: coupon.eventNo,
      encStr: coupon.encStr,
      isAutoDownloadedCoupon: coupon.isAutoDownloadedCoupon,
      selectedByRecommendation: false,
      issuable: false,
    }
  }

const _asUnitCouponBoxTableItemViewData =
  (state: RootState) =>
  (coupon: UnitCoupon): CouponBoxTableItemViewData => {
    return {
      isDiscount: false,
      issueNo: coupon.issuable
        ? NotDownloadedCouponIssueNo
        : coupon.couponIssueNo,
      policyNo: coupon.couponPolicyNo,
      description: coupon.couponName,
      price: coupon.couponPrice,
      expiredDate: coupon.expiredDate,
      possiblePrice: coupon.possiblePrice,
      limitPrice: coupon.limitPrice,
      isSmileclubCoupon: coupon.isSmileclubCoupon,
      isBizCoupon: coupon.isBizCoupon,
      isMobileCoupon: coupon.isMobileCoupon,
      isAppCoupon: coupon.isAppCoupon,
      isDeliveryCoupon: coupon.isDeliveryCoupon,
      isApplied: coupon.applied,
      isAppliedOnAnother: coupon.appliedOnAnother,
      appliedOnAnotherPrice: coupon.appliedPriceOnAnother,
      discountUnitType: coupon.discountUnitType,
      paymentCouponName: coupon.paymentCouponName,
      selectedByRecommendation: coupon.selectedByRecommendation,
      issuable: coupon.issuable,
      isAutoDownloadedCoupon: coupon.isAutoDownloadedCoupon,
      isDownloading: coupon.isDownloading,
      isNotifiedPaymentCoupon: coupon.isNotifiedPaymentCoupon,
      isClubNudgingInsertCoupon: coupon.isClubNudgingInsertCoupon,
      clubWelcomeGift: coupon.isClubNudgingInsertCoupon
        ? state.unitCoupon.clubWelcomeGift || 0
        : 0,
    }
  }

export const getIsOneClickTarget = (state: RootState): boolean => {
  return state.buyer.isSmileClubOneClickTarget
}

/**
 * 주문단위 금액 합산
 * shallowEqual 사용 필수
 * @param state
 * @param cartUnitId
 */
export const getCartUnitPriceInfo = (
  state: RootState,
  cartUnitId: number,
): CartUnitPriceType => {
  const cartUnitPriceBasicInfo = fromCart.getCartUnitPriceBasicInfo(
    state.cart,
    cartUnitId,
    state.smileFresh.currentBranchServiceType,
  )

  const fundingDiscount = fromFundingDiscount.getAppliedFundingDiscount(
    state.fundingDiscount,
    cartUnitId,
  )

  const unitCoupons = fromUnitCoupon.getAppliedUnitCouponList(
    state.unitCoupon,
    cartUnitId,
  )

  const buyerUnitCouponPrice = unitCoupons
    .filter((x) => x.couponType === 'BuyerCoupon')
    .reduce((result, x) => result + x.couponPrice, 0)

  const totalUnitCouponPrice = unitCoupons.reduce(
    (result, x) => result + x.couponPrice,
    0,
  )

  const fundingDiscountPrice = fundingDiscount
    ? fundingDiscount.discountPrice
    : 0

  const totalDiscountPrice =
    cartUnitPriceBasicInfo.cartUnitPartnershipDiscountPrice +
    cartUnitPriceBasicInfo.cartUnitSellerDiscountPrice +
    cartUnitPriceBasicInfo.cartUnitBundleDiscountPrice +
    cartUnitPriceBasicInfo.cartUnitEbayDiscountPrice +
    fundingDiscountPrice

  const discountPriceWithoutEbayCharge =
    totalDiscountPrice - cartUnitPriceBasicInfo.cartUnitEbayDiscountPrice

  // 바이어 쿠폰 적용시 이베이 부담 할인 제거
  const discountPrice =
    buyerUnitCouponPrice > 0
      ? discountPriceWithoutEbayCharge
      : totalDiscountPrice

  const cartUnitPriceWithoutUnitCouponPrice =
    cartUnitPriceBasicInfo.cartUnitItemPrice -
    discountPrice +
    cartUnitPriceBasicInfo.cartUnitBundleDiscountPrice

  const cartUnitPrice =
    cartUnitPriceBasicInfo.cartUnitItemPrice -
    discountPrice -
    totalUnitCouponPrice

  const isSmileFresh = state.cart.cartUnitList.find(
    (x) => x.cartUnitId === cartUnitId && x.cartUnitType === 'SmileFresh',
  )

  const cartUnitItemPriceByShipping = isSmileFresh
    ? cartUnitPriceBasicInfo.cartUnitItemPrice -
      cartUnitPriceBasicInfo.cartUnitBundleDiscountPrice
    : cartUnitPriceBasicInfo.cartUnitItemPrice

  return {
    cartUnitItemPrice: cartUnitPriceBasicInfo.cartUnitItemPrice,

    cartUnitAdditionsTotalPrice:
      cartUnitPriceBasicInfo.cartUnitAdditionsTotalPrice,

    cartUnitOptionsAdditionalPrice:
      cartUnitPriceBasicInfo.cartUnitOptionsAdditionalPrice,

    cartUnitBranchAdditionalPrice:
      cartUnitPriceBasicInfo.cartUnitBranchAdditionalPrice,

    cartUnitBranchAdditionalTotalPrice:
      cartUnitPriceBasicInfo.cartUnitBranchAdditionalTotalPrice,

    cartUnitDiscountPrice: discountPrice,

    cartUnitEbayDiscountPrice: cartUnitPriceBasicInfo.cartUnitEbayDiscountPrice,

    cartUnitBundleDiscountPrice:
      cartUnitPriceBasicInfo.cartUnitBundleDiscountPrice,

    cartUnitSellerDiscountPrice:
      cartUnitPriceBasicInfo.cartUnitSellerDiscountPrice,

    cartUnitPartnershipDiscountPrice:
      cartUnitPriceBasicInfo.cartUnitPartnershipDiscountPrice,

    cartUnitFundingDiscountPrice: fundingDiscountPrice,

    cartUnitCouponPrice: totalUnitCouponPrice,

    cartUnitBuyerCouponPrice: buyerUnitCouponPrice,

    cartUnitBuyerCouponOrEbayDiscountPrice:
      buyerUnitCouponPrice || cartUnitPriceBasicInfo.cartUnitEbayDiscountPrice,

    cartUnitPrice,

    cartUnitPriceWithoutUnitCouponPrice: cartUnitPriceWithoutUnitCouponPrice,
    cartUnitItemPriceByShipping: cartUnitItemPriceByShipping,
  }
}

export const getCartBundlePriceInfo = (
  state: RootState,
  cartUnitIdList: number[],
): CartUnitPriceType[] => {
  return cartUnitIdList.map((cartUnitId) =>
    getCartUnitPriceInfo(state, cartUnitId),
  )
}

/**
 * 옥션 추천된 쿠폰 발급번호 조회
 */
export const getRecommendedCouponIssueNo = (
  state: GroupCouponState,
  availableCouponList: CouponBoxTableItemViewData[],
  recommendedGroupCouponKey: string,
): number => {
  const recommendCouponList = fromGroupCoupon.getRecommendedGroupCouponList(
    state,
    recommendedGroupCouponKey,
  )
  const availableCouponPolicyNoList = availableCouponList.map(
    (coupon) => coupon.policyNo,
  )

  const recommendCoupon = recommendCouponList.find((coupon) =>
    availableCouponPolicyNoList.includes(coupon.couponPolicyNo),
  )

  if (recommendCoupon) {
    if (recommendCoupon.couponIssueNo) {
      return recommendCoupon.couponIssueNo
    } else {
      const autoDownloadedCoupon = availableCouponList.find(
        ({ policyNo, isAutoDownloadedCoupon }) =>
          policyNo === recommendCoupon.couponPolicyNo && isAutoDownloadedCoupon,
      )

      return autoDownloadedCoupon
        ? autoDownloadedCoupon.issueNo
        : NotUseCouponDummyIssueNo
    }
  } else {
    return NotUseCouponDummyIssueNo
  }
}

/**
 * 옥션용
 * 쿠폰함에 뿌려줄 데이터 (할인, 쿠폰 통합)
 * @param state
 * @param cartUnitIdList
 * @returns Array<CouponBoxTableViewData>
 */
export const getGroupCouponBoxViewData = (
  state: RootState,
  cartUnitIdList: number[],
  recommendedGroupCouponKey: string,
): CouponBoxTableViewData[] => {
  if (!cartUnitIdList || cartUnitIdList.length === 0) {
    return []
  }
  const cartUnitList = fromCart.getCartUnitListByIdList(
    state.cart,
    cartUnitIdList,
  )

  // 옥션 할인
  const ebayDiscount: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    isDiscount: true,
    description: '옥션 추가할인',
    price: sumBy(cartUnitList, (cartUnit) =>
      sumBy(cartUnit.discounts, (discount) =>
        discount.discountType === 'Ebay' ? discount.discountPrice : 0,
      ),
    ),
  }

  // 판매자 할인
  const sellerDiscount: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    isDiscount: true,
    description: '판매자 즉시할인',
    price: sumBy(cartUnitList, (cartUnit) =>
      sumBy(cartUnit.discounts, (discount) =>
        discount.discountType === 'Seller' ? discount.discountPrice : 0,
      ),
    ),
  }

  // 복수구매 할인
  const bundleDiscount: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    isDiscount: true,
    description: '복수구매 할인',
    price: sumBy(cartUnitList, (cartUnit) =>
      sumBy(cartUnit.discounts, (discount) =>
        discount.discountType === 'Bundle' ? discount.discountPrice : 0,
      ),
    ),
  }

  // 우수회원 할인
  const memberDiscount: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    isDiscount: true,
    description: '우수회원 할인',
    price: sumBy(cartUnitList, (cartUnit) =>
      sumBy(cartUnit.discounts, (discount) =>
        discount.discountType === 'Member' ? discount.discountPrice : 0,
      ),
    ),
  }

  // 사용가능한 쿠폰 조회 (쿠폰/ 중복쿠폰)
  const availableCouponList = fromGroupCoupon.getAvailableGroupCouponList(
    state.groupCoupon,
    cartUnitIdList,
  )

  // 바이어 쿠폰
  const buyerCoupons: Array<CouponBoxTableItemViewData> = availableCouponList
    .filter((coupon) => coupon.couponType === 'BuyerCoupon')
    .map(_asGroupCouponBoxTableItemViewData(state, cartUnitIdList))
    .sort((l, r) => (l.price > r.price ? -1 : 1))

  const pcsDiscountPrice = chain(cartUnitList)
    .flatMap((cartUnit) => cartUnit.discounts)
    .filter((discount) => discount.discountType === 'Partnership')
    .sumBy((discount) => discount.discountPrice)
    .value()

  const fakeCoupons: Array<CouponBoxTableItemViewData> = pcsDiscountPrice
    ? [
        {
          issueNo: -2, // 다른종류 추가시 -2, -3 처럼 추가할것
          price: pcsDiscountPrice,
          isFakeCoupon: true,
          text: `${pcsDiscountPrice}`,
          unit: '원',
          description: '제휴 할인',
          fakeCouponType: 'PCS',
          isSmileclubCoupon: false,
        },
      ]
    : []

  /**
   * 쿠폰함에 쿠폰인척 하는 넛징 노출을 위한 fakeCoupon
   * 또 다른 케이스 추가되면 이런식으로 추가하자
   * 1. 스마일클럽 원클릭가입
   */
  // if (
  //   state.buyer.isSmileClubOneClickTarget &&
  //   sumBy(
  //     cartUnitIdList,
  //     (cartUnitId) => getCartUnitPriceInfo(state, cartUnitId).cartUnitItemPrice,
  //   ) -
  //     sellerDiscount.price >=
  //     15000
  // ) {
  //   fakeCoupons.push({
  //     issueNo: -1, // 다른종류 추가시 -2, -3 처럼 추가할것
  //     price: 0,
  //     isFakeCoupon: true,
  //     text: '12%',
  //     unit: '할인',
  //     description: '스마일클럽만 드리는 쿠폰',
  //     subDescription: '한 달 무료체험하고 쿠폰 사용하세요',
  //     fakeCouponType: 'SmileClubOneClick',
  //     areaCode: areaCodes.ONE_CLICK_COUPON,
  //     impressionCode: areaCodes.ONE_CLICK_COUPON,
  //     isSmileclubCoupon: true,
  //   })
  // }

  // 중복쿠폰
  const superCoupons: Array<CouponBoxTableItemViewData> = availableCouponList
    .filter((coupon) => coupon.couponType === 'SuperCoupon')
    .map(_asGroupCouponBoxTableItemViewData(state, cartUnitIdList))
    .sort((l, r) => (l.price > r.price ? -1 : 1))

  const availableFundingDiscounts =
    fromFundingDiscount.getAvailableFundingDiscountListByGroup(
      state.fundingDiscount,
      cartUnitIdList,
    )

  const fundingDiscounts: CouponBoxTableItemViewData[] =
    availableFundingDiscounts.map((x) => {
      return {
        issueNo: x.discountPolicyNo,
        description: x.displayName,
        price: x.discountPrice,
      }
    })

  // 사용 안함
  const notUseCouponViewData: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    price: 0,
    isNotUseCoupon: true,
  }

  // 옥션할인, 중복할인 쿠폰 중 적용된 쿠폰이 있는지 확인
  const existAppliedCoupons = buyerCoupons
    .concat(superCoupons)
    .some((coupon) => coupon.isApplied)

  // 옥션할인 테이블
  const appliedBuyerCoupon = buyerCoupons.find((coupon) => coupon.isApplied)
  const discountOrNotUseCouponViewData: CouponBoxTableItemViewData =
    ebayDiscount.price && ebayDiscount.price > 0
      ? ebayDiscount
      : notUseCouponViewData
  const buyerCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo: existAppliedCoupons
      ? appliedBuyerCoupon
        ? appliedBuyerCoupon.issueNo
        : NotUseCouponDummyIssueNo
      : getRecommendedCouponIssueNo(
          state.groupCoupon,
          buyerCoupons,
          recommendedGroupCouponKey,
        ),
    type: CouponBoxTableType.Buyer,
    coupons: [...fakeCoupons, discountOrNotUseCouponViewData, ...buyerCoupons],
    isIncludeCoupon: true,
  }

  // 판매자할인 테이블
  const sellerCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo: NotUseCouponDummyIssueNo,
    type: CouponBoxTableType.Seller,
    coupons: [sellerDiscount].filter(
      (coupon) => coupon.price && coupon.price > 0,
    ),
  }

  // 중복할인 테이블
  const appliedSuperCoupon = superCoupons.find((coupon) => coupon.isApplied)
  const superCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo: existAppliedCoupons
      ? appliedSuperCoupon
        ? appliedSuperCoupon.issueNo
        : NotUseCouponDummyIssueNo
      : getRecommendedCouponIssueNo(
          state.groupCoupon,
          superCoupons,
          recommendedGroupCouponKey,
        ),
    type: CouponBoxTableType.Super,
    coupons: [notUseCouponViewData, ...[...superCoupons]],
    isIncludeCoupon: true,
  }

  // 우수/복수할인 테이블
  const bundleOrMemberCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo: NotUseCouponDummyIssueNo,
    type: CouponBoxTableType.Bundle,
    coupons: [bundleDiscount, memberDiscount].filter(
      (coupon) => coupon.price && coupon.price > 0,
    ),
  }

  // 항상 함께 적용되기때문에 첫번째거로 조회하면 된다(금액은 사용하면 안됨)
  const appliedFundingDiscount = fromFundingDiscount.getAppliedFundingDiscount(
    state.fundingDiscount,
    cartUnitIdList[0],
  )

  // 노출 순서대로 셋팅
  return [
    sellerCouponBoxTable,
    buyerCouponBoxTable,
    superCouponBoxTable,
    bundleOrMemberCouponBoxTable,
  ]
}

/**
 * G/G9
 * 쿠폰함에 뿌려줄 데이터 (할인, 쿠폰 통합)
 * @param state
 * @param cartUnitId
 * @returns Array<CouponBoxTableViewData>
 */
export const getUnitCouponBoxViewData = (
  state: RootState,
  cartUnitId: number,
): CouponBoxTableViewData[] => {
  const cartUnit = fromCart.getCartUnitById(state.cart, cartUnitId)
  if (!cartUnit) {
    return []
  }

  // G마켓 / G9 할인
  const ebayDiscount: CouponBoxTableItemViewData = {
    issueNo: ItemDiscountCouponDummyIssueNo,
    isDiscount: true,
    description:
      state.view.tenantType === 'G9'
        ? 'G9 할인'
        : __('REWARD_COUPON_TEXT_5', 'G마켓 할인'),
    price: sumBy(cartUnit.discounts, (discount) =>
      discount.discountType === 'Ebay' ? discount.discountPrice : 0,
    ),
  }

  // 판매자 할인
  const sellerDiscount: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    isDiscount: true,
    description: '판매자 즉시할인',
    price: sumBy(cartUnit.discounts, (discount) =>
      discount.discountType === 'Seller' ? discount.discountPrice : 0,
    ),
  }

  // 복수구매 할인
  const bundleDiscount: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    isDiscount: true,
    description: '복수구매 할인',
    price: sumBy(cartUnit.discounts, (discount) =>
      discount.discountType === 'Bundle' ? discount.discountPrice : 0,
    ),
  }

  // 사용가능한 쿠폰 조회 (쿠폰/ 중복쿠폰)
  const availableCouponList = fromUnitCoupon.getAvailableUnitCouponList(
    state.unitCoupon,
    cartUnitId,
  )

  // 바이어 쿠폰
  const buyerCoupons: Array<CouponBoxTableItemViewData> = availableCouponList
    .filter((coupon) => coupon.couponType === 'BuyerCoupon')
    .map(_asUnitCouponBoxTableItemViewData(state))

  const pcsDiscountPrice = fromCart
    .getCartUnitById(state.cart, cartUnitId)
    ?.discounts.find((x) => x.discountType === 'Partnership')?.discountPrice

  const fakeCoupons: Array<CouponBoxTableItemViewData> = pcsDiscountPrice
    ? [
        {
          issueNo: PcsFakeCouponIssueNo,
          price: pcsDiscountPrice,
          isFakeCoupon: true,
          text: `${pcsDiscountPrice}`,
          unit: '원',
          description: '제휴 할인',
          fakeCouponType: 'PCS',
          isSmileclubCoupon: false,
        },
      ]
    : []

  /**
   * 쿠폰함에 쿠폰인척 하는 넛징 노출을 위한 fakeCoupon
   * 또 다른 케이스 추가되면 이런식으로 추가하자
   * 1. 스마일클럽 원클릭가입
   */
  // if (
  //   // true
  //   state.buyer.isSmileClubOneClickTarget &&
  //   getCartUnitPriceInfo(state, cartUnitId).cartUnitItemPrice -
  //     sellerDiscount.price >=
  //     15000
  // ) {
  //   fakeCoupons.push({
  //     issueNo: -1, // 다른종류 추가시 -2, -3 처럼 추가할것
  //     price: 0,
  //     isFakeCoupon: true,
  //     text: '12%',
  //     unit: '할인',
  //     description: '스마일클럽만 드리는 쿠폰',
  //     subDescription: '한 달 무료체험하고 쿠폰 사용하세요',
  //     fakeCouponType: 'SmileClubOneClick',
  //     areaCode: areaCodes.ONE_CLICK_COUPON,
  //     impressionCode: areaCodes.ONE_CLICK_COUPON,
  //     isSmileclubCoupon: true,
  //   })
  // }

  // 중복쿠폰
  const superCoupons: Array<CouponBoxTableItemViewData> = availableCouponList
    .filter((coupon) => coupon.couponType === 'SuperCoupon')
    .map(_asUnitCouponBoxTableItemViewData(state))

  // 사용 안함
  const notUseCouponViewData: CouponBoxTableItemViewData = {
    issueNo: NotUseCouponDummyIssueNo,
    price: 0,
    isNotUseCoupon: true,
  }

  // 지마켓/지구 할인 테이블
  const discountOrNotUseCouponViewData: CouponBoxTableItemViewData =
    ebayDiscount.price && ebayDiscount.price > 0
      ? ebayDiscount
      : notUseCouponViewData

  // 바이어쿠폰 테이블
  //// 선택된 쿠폰 우선순위
  //// 1. 적용되어 있는 쿠폰
  //// 2. 발급된 쿠폰 중 추천하는 쿠폰
  //// 3. 아이템할인 또는 PCS할인 (아이템할인과 PCS할인은 동시에 있을 수 없음)
  const buyerCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo:
      buyerCoupons.find(({ isApplied }) => isApplied)?.issueNo ||
      buyerCoupons.find(
        ({ selectedByRecommendation, issuable }) =>
          selectedByRecommendation && !issuable,
      )?.issueNo ||
      (ebayDiscount.price && ebayDiscount.price > 0
        ? ItemDiscountCouponDummyIssueNo
        : undefined) ||
      (pcsDiscountPrice && pcsDiscountPrice > 0
        ? PcsFakeCouponIssueNo
        : undefined) ||
      NotUseCouponDummyIssueNo,
    type: CouponBoxTableType.Buyer,
    coupons: [...fakeCoupons, discountOrNotUseCouponViewData, ...buyerCoupons],
    isIncludeCoupon: true,
  }

  // 판매자할인 테이블
  const sellerCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo: NotUseCouponDummyIssueNo,
    type: CouponBoxTableType.Seller,
    coupons: [sellerDiscount].filter(
      (coupon) => coupon.price && coupon.price > 0,
    ),
  }

  // 중복할인 테이블
  const superCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo:
      superCoupons.find(({ isApplied }) => isApplied)?.issueNo ||
      superCoupons.find(
        ({ selectedByRecommendation, issuable }) =>
          selectedByRecommendation && !issuable,
      )?.issueNo ||
      NotUseCouponDummyIssueNo,
    type: CouponBoxTableType.Super,
    coupons: [notUseCouponViewData, ...[...superCoupons]],
    isIncludeCoupon: true,
  }

  // 복수할인 테이블
  const bundleCouponBoxTable: CouponBoxTableViewData = {
    selectedCouponIssueNo: NotUseCouponDummyIssueNo,
    type: CouponBoxTableType.Bundle,
    coupons: [bundleDiscount].filter(
      (coupon) => coupon.price && coupon.price > 0,
    ),
  }

  // 노출 순서대로 셋팅
  return [
    sellerCouponBoxTable,
    bundleCouponBoxTable,
    buyerCouponBoxTable,
    superCouponBoxTable,
  ]
}

export const getCartUnitSummaryInfo = (
  state: RootState,
  cartUnitIdList: number[],
): CartUnitSummaryInfoType => {
  const defaultSummary: CartUnitSummaryInfoType = {
    cartUnitCount: cartUnitIdList.length,
    totalItemPrice: 0,
    totalDiscountPrice: 0,
    totalEbayDiscountPrice: 0,
    totalBundleDiscountPrice: 0,
    totalSellerDiscountPrice: 0,
    totalFundingDiscountPrice: 0,
    totalPartnershipDiscountPrice: 0,
    totalUnitCouponPrice: 0,
    totalBuyerUnitCouponPrice: 0,
    totalBuyerCouponOrEbayDiscountPrice: 0,
  }

  return cartUnitIdList.reduce<CartUnitSummaryInfoType>(
    (result, cartUnitId) => {
      const price = getCartUnitPriceInfo(state, cartUnitId)
      return {
        ...result,
        // 주문단위별 상품 금액 총합
        totalItemPrice: result.totalItemPrice + price.cartUnitItemPrice,
        // 주문단위별 할인 금액 총합
        totalDiscountPrice:
          result.totalDiscountPrice + price.cartUnitDiscountPrice,
        // 그룹쿠폰적용시 사용을 위한 주문단위별 비 이베이부담 할인 금액 총합
        totalEbayDiscountPrice:
          result.totalEbayDiscountPrice + price.cartUnitEbayDiscountPrice,
        // 복수할인
        totalBundleDiscountPrice:
          result.totalBundleDiscountPrice + price.cartUnitBundleDiscountPrice,
        // 판매자할인
        totalSellerDiscountPrice:
          result.totalSellerDiscountPrice + price.cartUnitSellerDiscountPrice,
        // 펀딩할인
        totalFundingDiscountPrice:
          result.totalFundingDiscountPrice + price.cartUnitFundingDiscountPrice,
        // 제휴할인(PCS)
        totalPartnershipDiscountPrice:
          result.totalPartnershipDiscountPrice +
          price.cartUnitPartnershipDiscountPrice,
        // 주문단위별 쿠폰(지마켓) 금액 총합
        totalUnitCouponPrice:
          result.totalUnitCouponPrice + price.cartUnitCouponPrice,
        // 주문단위별 바이어쿠폰(지마켓) 금액 총합
        totalBuyerUnitCouponPrice:
          result.totalBuyerUnitCouponPrice + price.cartUnitBuyerCouponPrice,
        // 지마켓부담 할인 or 쿠폰
        totalBuyerCouponOrEbayDiscountPrice:
          result.totalBuyerCouponOrEbayDiscountPrice +
          price.cartUnitBuyerCouponOrEbayDiscountPrice,
      }
    },
    defaultSummary,
  )
}

/**
 * 상품그룹 금액 합산
 * shallowEqual 사용필수
 * @param state
 * @param itemGroup
 * @param selectedCartUnitIdList
 */
export const getItemGroupSummary = (
  state: RootState,
  itemGroup: ItemGroupView,
  selectedCartUnitIdList?: number[],
): ItemGroupSummaryType => {
  const cartUnitIdList = selectedCartUnitIdList || itemGroup.cartUnitIdList
  const cartUnitSummary = getCartUnitSummaryInfo(state, cartUnitIdList)
  const appliedGroupCoupons = fromGroupCoupon.getAppliedGroupCouponList(
    state.groupCoupon,
    cartUnitIdList,
  )

  const totalBuyerGroupCouponPrice = appliedGroupCoupons.reduce(
    (result, coupon) =>
      result + (coupon.couponType === 'BuyerCoupon' ? coupon.couponPrice : 0),
    0,
  )

  const totalGroupCouponPrice = appliedGroupCoupons.reduce(
    (result, coupon) => result + coupon.couponPrice,
    0,
  )

  // 쿠폰 적용시 이베이부담할인 제거
  const totalDiscountPrice =
    totalBuyerGroupCouponPrice > 0
      ? cartUnitSummary.totalDiscountPrice -
        cartUnitSummary.totalEbayDiscountPrice
      : cartUnitSummary.totalDiscountPrice

  // 할인(쿠폰포함)금액: 할인금액 + 쿠폰금액 + 그룹쿠폰금액 + 즉시할인금액
  const totalNegativePrice =
    totalDiscountPrice +
    totalGroupCouponPrice +
    cartUnitSummary.totalUnitCouponPrice

  const totalPrice = cartUnitSummary.totalItemPrice - totalNegativePrice

  return {
    cartUnitCount: cartUnitSummary.cartUnitCount,
    totalItemPrice: cartUnitSummary.totalItemPrice,
    totalDiscountPrice,
    totalCouponPrice:
      cartUnitSummary.totalUnitCouponPrice + totalGroupCouponPrice,
    totalBuyerCouponPrice:
      cartUnitSummary.totalBuyerUnitCouponPrice + totalBuyerGroupCouponPrice,
    totalBuyerCouponOrEbayDiscountPrice:
      totalBuyerGroupCouponPrice ||
      cartUnitSummary.totalBuyerCouponOrEbayDiscountPrice,
    totalNegativePrice,
    totalFundingDiscountPrice: cartUnitSummary.totalFundingDiscountPrice,
    totalBundleDiscountPrice: cartUnitSummary.totalBundleDiscountPrice,
    totalSellerDiscountPrice: cartUnitSummary.totalSellerDiscountPrice,
    totalPartnershipDiscountPrice:
      cartUnitSummary.totalPartnershipDiscountPrice,
    totalPrice,
  }
}

const getBuyUnavailableMessage = (unableOrderType: UnableOrderType): string => {
  switch (unableOrderType) {
    case 'ITEM_STOP_SELLING':
      return __('ESCROW_BASKET_TEXT_188', '판매 종료되어 주문할 수 없습니다.')
    case 'ITEM_OUT_OF_STOCK':
      return __('ESCROW_BASKET_TEXT_189', '품절되어 주문할 수 없습니다.')
    case 'OPTION_OUT_OF_STOCK':
      return __(
        'ESCROW_BASKET_TEXT_200',
        '옵션 정보/재고가 변경되어 주문할 수 없습니다.\n다시 장바구니에 담아주세요.',
      )
    case 'ADDITION_OUT_OF_STOCK':
      return __(
        'ESCROW_BASKET_TEXT_201',
        '추가구성 정보/재고가 변경되어 주문할 수 없습니다.\n다시 장바구니에 담아주세요.',
      )
    case 'ONLY_BUYNOW':
      return __(
        'ESCROW_BASKET_TEXT_192',
        '장바구니에 담을 수 없는 상품입니다.즉시 구매해주시기 바랍니다.',
      )
    case 'BRANCH_CHANGE_POLICY':
      return __(
        'ESCROW_BASKET_TEXT_193',
        '배송지점이 변경되어 주문할 수 없습니다.',
      )
    case 'ITEM_PRICE_MINIMUM':
      return __(
        'ESCROW_BASKET_TEXT_194',
        '상품금액이 변경 되어 주문 할 수 없습니다.',
      )
    case 'ADULT_LIMIT':
      return __('ESCROW_BASKET_TEXT_195', '성인상품으로 인증이 필요합니다.')
    case 'VERO_POLICY':
      return __(
        'ESCROW_BASKET_TEXT_195',
        '상품의 정보가 변경되어 주문할 수 없습니다.',
      )
    case 'SELF_LIMIT':
      return __(
        'ESCROW_BASKET_TEXT_196',
        '본인이 등록한 상품을 구매할 수 없습니다.',
      )
    case 'BUYER_LIMIT_POLICY':
      return __(
        'ESCROW_BASKET_TEXT_197',
        `고객님께서는 상품구매가 제한되어 있습니다.\n문의사항이 있으시면 고객센터로 연락하여 주시기 바랍니다.\n(${tenantConstants.CustomerCenterContact})`,
      )
    default:
      return __(
        'ESCROW_BASKET_TEXT_198',
        '상품정보 변경 및 품절로 인하여 주문할 수 없습니다.',
      )
  }
}

export const getBuyUnavailableInfo = (
  state: RootState,
  cartUnitId: number,
): BuyUnavailableInfo | undefined => {
  const cartUnit = getCartUnitById(state.cart, cartUnitId)
  if (!cartUnit) {
    return
  }

  if (!cartUnit.isBuyAvailable && cartUnit.unableOrderType) {
    return {
      unableOrderType: cartUnit.unableOrderType,
      defaultMessage: getBuyUnavailableMessage(cartUnit.unableOrderType),
    }
  }

  if (cartUnit.cartUnitType === 'SmileFresh') {
    if (
      cartUnit.smileFreshBranches.length === 0 ||
      state.smileFresh.availableBranches.length === 0
    ) {
      return {
        unableOrderType: 'NO_SMILE_FRESH_BRANCH',
        defaultMessage: '해당 주소에서 주문할 수 없는 상품이에요',
      }
    }

    const currentBranchServiceType = state.smileFresh.currentBranchServiceType

    if (
      !cartUnit.smileFreshBranches.some((x) =>
        x.branchServiceTypes.includes(currentBranchServiceType),
      )
    ) {
      if (currentBranchServiceType === 'Dawn') {
        // 현재가 새벽인데 불가하면 낮밤배송만
        return {
          unableOrderType: 'ONLY_SMILE_FRESH_RESERVE_BRANCH',
          defaultMessage: '낮밤배송으로만 주문할 수 있어요',
        }
      } else {
        return {
          unableOrderType: 'ONLY_SMILE_FRESH_DAWN_BRANCH',
          defaultMessage: '새벽배송으로만 주문할 수 있어요',
        }
      }
    }
  }
}

export const getCartUnitList = (
  state: RootState,
  withBuyUnavailable?: boolean,
  cartUnitIdList?: number[],
): CartUnit[] =>
  fromCart
    .getCurrentCartUnitList(state.cart)
    .filter(
      (cartUnit) =>
        (!cartUnitIdList || cartUnitIdList.includes(cartUnit.cartUnitId)) &&
        (withBuyUnavailable ||
          !getBuyUnavailableInfo(state, cartUnit.cartUnitId)),
    )

export const getSelectedCartUnitList = (
  state: RootState,
  withBuyUnavailable?: boolean,
  cartUnitIdList?: number[],
): CartUnit[] =>
  getCartUnitList(state, withBuyUnavailable, cartUnitIdList).filter(
    (cartUnit) =>
      state.cart.selectedCartUnitIdList.includes(cartUnit.cartUnitId),
  )

export const getExhibitExtraDiscountList = (
  state: RootState,
): ExhibitExtraDiscountList[] =>
  getSelectedCartUnitList(state)
    .slice(0, 30)
    .map((cartUnit) => {
      const { item, seller } = cartUnit
      const { cartUnitPrice } = getCartUnitPriceInfo(state, cartUnit.cartUnitId)
      return {
        appliedCoupons: cartUnit.appliedCouponIssueNoes.map((couponNo) => {
          return {
            couponNo: couponNo.toString(),
          }
        }),
        gdlcCode: item.largeCategoryCode,
        gdmcCode: item.mediumCategoryCode,
        gdscCode: item.smallCategoryCode,
        itemNo: item.itemNo,
        requestKey: cartUnit.cartUnitId.toString(),
        sellerCustNo: seller.sellerKey,
        targetPrice: cartUnitPrice,
      }
    })

export const getSelectedCartUnitShippingWeight = (state: RootState): number => {
  return getSelectedCartUnitList(state).reduce((result, cartUnit) => {
    return parseFloat(
      (
        result +
        (cartUnit.item.shippingWeight || 0) * cartUnit.quantity
      ).toFixed(3),
    )
  }, 0)
}

// 전체 카트유닛들의 무게 리스트
export const getCurrentCartUnitShippingWeightList = (
  state: RootState,
): OverseaShippingFeeRequest[] => {
  return fromCart.getCurrentCartUnitList(state.cart).map((cartUnit) => {
    return {
      cartUnitId: cartUnit.cartUnitId,
      shippingWeight: parseFloat(
        ((cartUnit.item.shippingWeight || 0) * cartUnit.quantity).toFixed(3),
      ),
    }
  })
}

/**
 * 상품그룹 금액 합산(선택한것만)
 * shallowEqual 사용필수
 * @param state
 * @param itemGroup
 */
export const getSelectedItemGroupSummary = (
  state: RootState,
  itemGroup: ItemGroupView,
): ItemGroupSummaryType => {
  const selectedCartUnitList = getSelectedCartUnitList(
    state,
    false,
    itemGroup.cartUnitIdList,
  )
  return getItemGroupSummary(
    state,
    itemGroup,
    selectedCartUnitList.map((x) => x.cartUnitId),
  )
}

/**
 * 배송그룹 금액 합산
 * shallowEqual 사용필수
 * @param state
 * @param shippingGroup
 */
export const getSelectedShippingGroupSummary = (
  state: RootState,
  shippingGroup: ShippingGroupView,
): ShippingGroupSummaryType => {
  const defaultSummary: ShippingGroupSummaryType = {
    cartUnitCount: 0,
    totalItemPrice: 0,
    totalDiscountPrice: 0,
    totalNegativePrice: 0,
    totalCouponPrice: 0,
    totalBuyerCouponPrice: 0,
    totalBuyerCouponOrEbayDiscountPrice: 0,
    totalFundingDiscountPrice: 0,
    totalBundleDiscountPrice: 0,
    totalSellerDiscountPrice: 0,
    totalPartnershipDiscountPrice: 0,
    totalPrice: 0,
  }

  return shippingGroup.itemGroups.reduce<ShippingGroupSummaryType>(
    (result, itemGroup) => {
      const itemGroupSummary = getSelectedItemGroupSummary(state, itemGroup)
      return {
        ...result,
        cartUnitCount: result.cartUnitCount + itemGroupSummary.cartUnitCount,
        totalItemPrice:
          (result.totalItemPrice || 0) + itemGroupSummary.totalItemPrice,
        totalDiscountPrice:
          (result.totalDiscountPrice || 0) +
          itemGroupSummary.totalDiscountPrice,
        totalNegativePrice:
          (result.totalNegativePrice || 0) +
          itemGroupSummary.totalNegativePrice,
        totalCouponPrice:
          (result.totalCouponPrice || 0) + itemGroupSummary.totalCouponPrice,
        totalBuyerCouponPrice:
          (result.totalBuyerCouponPrice || 0) +
          itemGroupSummary.totalBuyerCouponPrice,
        totalBuyerCouponOrEbayDiscountPrice:
          (result.totalBuyerCouponOrEbayDiscountPrice || 0) +
          itemGroupSummary.totalBuyerCouponOrEbayDiscountPrice,
        totalFundingDiscountPrice:
          (result.totalFundingDiscountPrice || 0) +
          itemGroupSummary.totalFundingDiscountPrice,
        totalBundleDiscountPrice:
          (result.totalBundleDiscountPrice || 0) +
          itemGroupSummary.totalBundleDiscountPrice,
        totalSellerDiscountPrice:
          (result.totalSellerDiscountPrice || 0) +
          itemGroupSummary.totalSellerDiscountPrice,
        totalPartnershipDiscountPrice:
          (result.totalPartnershipDiscountPrice || 0) +
          itemGroupSummary.totalPartnershipDiscountPrice,
        totalPrice: (result.totalPrice || 0) + itemGroupSummary.totalPrice,
      }
    },
    defaultSummary,
  )
}

/**
 * shallowEqual 필수
 * @param state
 * @param sellerGroup
 */
export const getSelectedSellerGroupSummary = (
  state: RootState,
  sellerGroup: SellerGroupView,
): SellerGroupSummaryType => {
  const selectedCartUnitList = getSelectedCartUnitList(
    state,
    false,
    sellerGroup.cartUnitIdList,
  )

  const bundles = groupBy(
    selectedCartUnitList,
    (cartUnit) => cartUnit.bundleKey,
  )

  const isAllSpecialShipping = every(
    groupBy(selectedCartUnitList, (cartUnit) => cartUnit.shippingPolicyKey),
    (cartUnits, shippingPolicyKey) =>
      fromShipping.getShippingPolicy(
        state.shipping,
        shippingPolicyKey,
        cartUnits[0].item.isECoupon,
      )?.isSpecialShipping || false,
  )

  const isBundleShipping = Object.values(bundles).some(
    (cartUnits) => cartUnits.length > 1,
  ) // 묶음배송비 적용중 노출조건

  const totalShippingFee = Object.values(bundles).reduce(
    (result, cartUnits) => {
      return cartUnits.length > 0
        ? result +
            fromShipping.getBundleShippingFee(
              state.shipping,
              cartUnits[0].bundleKey || '',
            )
        : 0
    },
    0,
  ) // 전체 배송비는 묶음배송비 그룹 기준으로 계산한다

  const totalInternationalShippingFee =
    fromShipping.getSellerGroupOverseaShippingCost(
      state.shipping,
      sellerGroup.cartUnitIdList,
    ) || 0

  const defaultSummary: SellerGroupSummaryType = {
    cartUnitCount: 0,
    totalItemPrice: 0,
    totalDiscountPrice: 0,
    totalBundleDiscountPrice: 0,
    totalCouponPrice: 0,
    totalBuyerCouponPrice: 0,
    totalBuyerCouponOrEbayDiscountPrice: 0,
    totalFundingDiscountPrice: 0,
    totalSellerDiscountPrice: 0,
    totalPartnershipDiscountPrice: 0,
    totalNegativePrice: 0,
    totalShippingFee,
    totalInternationalShippingFee,
    isBundleShipping,
    isAllSpecialShipping,
    totalPrice: totalShippingFee,
    sellerGroupDiscountPercent: 0,
  }

  return sellerGroup.shippingGroups.reduce<SellerGroupSummaryType>(
    (result, shippingGroup) => {
      const shippingGroupSummary = getSelectedShippingGroupSummary(
        state,
        shippingGroup,
      )

      // 할인율 (총할인금액/총상품금액 * 100, 소수점 1의 자리 버림)
      const totalDiscount =
        result.totalNegativePrice + shippingGroupSummary.totalNegativePrice
      const totalPrice =
        result.totalItemPrice + shippingGroupSummary.totalItemPrice

      const sellerGroupDiscountPercent =
        totalPrice > 0 && totalDiscount > 0
          ? Math.floor((totalDiscount / totalPrice) * 100)
          : 0

      return {
        ...result,
        cartUnitCount:
          result.cartUnitCount + shippingGroupSummary.cartUnitCount,
        totalItemPrice:
          result.totalItemPrice + shippingGroupSummary.totalItemPrice,
        totalDiscountPrice:
          result.totalDiscountPrice + shippingGroupSummary.totalDiscountPrice,
        totalBundleDiscountPrice:
          result.totalBundleDiscountPrice +
          shippingGroupSummary.totalBundleDiscountPrice,
        totalCouponPrice:
          result.totalCouponPrice + shippingGroupSummary.totalCouponPrice,
        totalBuyerCouponPrice:
          result.totalBuyerCouponPrice +
          shippingGroupSummary.totalBuyerCouponPrice,
        totalBuyerCouponOrEbayDiscountPrice:
          result.totalBuyerCouponOrEbayDiscountPrice +
          shippingGroupSummary.totalBuyerCouponOrEbayDiscountPrice,
        totalFundingDiscountPrice:
          result.totalFundingDiscountPrice +
          shippingGroupSummary.totalFundingDiscountPrice,
        totalSellerDiscountPrice:
          result.totalSellerDiscountPrice +
          shippingGroupSummary.totalSellerDiscountPrice,
        totalPartnershipDiscountPrice:
          result.totalPartnershipDiscountPrice +
          shippingGroupSummary.totalPartnershipDiscountPrice,
        totalNegativePrice:
          result.totalNegativePrice + shippingGroupSummary.totalNegativePrice,
        totalPrice: result.totalPrice + shippingGroupSummary.totalPrice,
        sellerGroupDiscountPercent: sellerGroupDiscountPercent,
      }
    },
    defaultSummary,
  )
}

/**
 * 전체 합산금액 계산
 * shallowEqual 사용필수
 * @param state
 */
export const getTotalSummary = (state: RootState): SummaryType => {
  const cartTree = state.cart.cartItemTreeView
  const totalInternationalShippingFee =
    fromShipping.getOverseaShippingCost(state.shipping) || 0

  const defaultSummary: SummaryType = {
    cartUnitCount: 0,
    totalItemPrice: 0,
    totalDiscountPrice: 0,
    totalBundleDiscountPrice: 0,
    totalCouponPrice: 0,
    totalBuyerCouponPrice: 0,
    totalBuyerCouponOrEbayDiscountPrice: 0,
    totalFundingDiscountPrice: 0,
    totalSellerDiscountPrice: 0,
    totalPartnershipDiscountPrice: 0,
    totalNegativePrice: 0,
    totalShippingFee: totalInternationalShippingFee,
    totalInternationalShippingFee,
    totalPrice: totalInternationalShippingFee,
    totalDiscountPercent: 0,
  }

  return cartTree.sellerGroups.reduce<SummaryType>((result, sellerGroup) => {
    const sellerGroupSummary = getSelectedSellerGroupSummary(state, sellerGroup)

    // 할인율 (총할인금액/총상품금액 * 100, 소수점 1의 자리 버림)
    const totalDiscount =
      result.totalNegativePrice + sellerGroupSummary.totalNegativePrice
    const totalPrice = result.totalItemPrice + sellerGroupSummary.totalItemPrice
    const totalDiscountPercent =
      totalPrice > 0 && totalDiscount > 0
        ? Math.floor((totalDiscount / totalPrice) * 100)
        : 0

    return {
      ...result,
      cartUnitCount: result.cartUnitCount + sellerGroupSummary.cartUnitCount,
      totalItemPrice: result.totalItemPrice + sellerGroupSummary.totalItemPrice,
      totalDiscountPrice:
        result.totalDiscountPrice + sellerGroupSummary.totalDiscountPrice,
      totalCouponPrice:
        result.totalCouponPrice + sellerGroupSummary.totalCouponPrice,
      totalBuyerCouponPrice:
        result.totalBuyerCouponPrice + sellerGroupSummary.totalBuyerCouponPrice,
      totalBuyerCouponOrEbayDiscountPrice:
        result.totalBuyerCouponOrEbayDiscountPrice +
        sellerGroupSummary.totalBuyerCouponOrEbayDiscountPrice,
      totalFundingDiscountPrice:
        result.totalFundingDiscountPrice +
        sellerGroupSummary.totalFundingDiscountPrice,
      totalSellerDiscountPrice:
        result.totalSellerDiscountPrice +
        sellerGroupSummary.totalSellerDiscountPrice,
      totalPartnershipDiscountPrice:
        result.totalPartnershipDiscountPrice +
        sellerGroupSummary.totalPartnershipDiscountPrice,
      totalNegativePrice:
        result.totalNegativePrice + sellerGroupSummary.totalNegativePrice,
      totalBundleDiscountPrice:
        result.totalBundleDiscountPrice +
        sellerGroupSummary.totalBundleDiscountPrice,
      totalShippingFee:
        result.totalShippingFee + sellerGroupSummary.totalShippingFee,
      totalPrice: result.totalPrice + sellerGroupSummary.totalPrice,
      totalDiscountPercent: totalDiscountPercent,
    }
  }, defaultSummary)
}

/**
 * 전체금액(스마일캐시 등 적용되지 않음)
 * @param state
 */
export const getTotalPrice = (state: RootState): number => {
  return getTotalSummary(state).totalPrice
}

/**
 * 캐시백 전체
 * shallowEqual 사용필수
 * @param state
 */
export const getTotalCashbackSummary = (
  state: RootState,
): TotalCashbackSummaryType => {
  const selectedCartUnitIdList = getSelectedCartUnitList(state).map(
    (x) => x.cartUnitId,
  )
  const cashbackSummary = fromCashback.getCashbackSummary(
    state.cashback,
    selectedCartUnitIdList,
  )
  const isSmileClub = state.buyer.isSmileClub
  const isDomesticBusinessman = state.buyer.isDomesticBusinessman
  const { isSSGMembership } =
    state.buyer.unifyMembershipInfo.smileClubMemberInformation

  // 이벤트 적립
  const maxEventCashback =
    cashbackSummary.itemCashback + cashbackSummary.cartCashback
  const appliedEventCashback = maxEventCashback // 장바구니는 결제수단 선택이 없으므로 항상 적용

  // 스마일페이 캐시백 최대적립 = 클럽용 스마일페이 캐시백
  const maxPayCashback = cashbackSummary.smilePayClubCashback

  // 판매자 제공 적립
  const sellerCashback = cashbackSummary.sellerCashback

  // 클럽데이 적립
  const maxClubDayCashback = cashbackSummary.clubDayCashback

  const appliedClubDayCashback = isSmileClub ? maxClubDayCashback : 0

  const appliedSsgPointCashback =
    isSmileClub && isSSGMembership && !isDomesticBusinessman
      ? cashbackSummary.ssgPointCashback
      : 0

  const totalCashbackAmount =
    maxEventCashback + // 이벤트 캐시백
    sellerCashback + // 판매자 제공 캐시백
    appliedClubDayCashback // 클럽데이 캐시백
  const maxCashbackAmount =
    maxEventCashback + sellerCashback + maxPayCashback + maxClubDayCashback
  const isMaxBenefit = totalCashbackAmount === maxCashbackAmount
  const isOnlySellerCashback = maxCashbackAmount === sellerCashback

  return {
    isMaxBenefit,
    isOnlySellerCashback,
    maxEventCashback,
    sellerCashback,
    maxPayCashback,
    maxClubDayCashback,
    appliedEventCashback,
    appliedClubDayCashback,
    clubDayCashbackRate: cashbackSummary.clubDayCashbackRate,
    appliedSsgPointCashback,
    totalCashbackAmount,
    maxCashbackAmount,
    useEnableDateUnix: cashbackSummary.useEnableDateUnix,
    ssgCashbackDate: cashbackSummary.ssgCashbackDate,
  }
}

const isSameMiniShop = (cartUnit1: CartUnit, cartUnit2: CartUnit): boolean => {
  if (cartUnit1.cartUnitType === 'SmileDelivery') {
    return cartUnit2.cartUnitType === 'SmileDelivery'
  } else if (cartUnit1.cartUnitType === 'SmileFresh') {
    return cartUnit2.cartUnitType === 'SmileFresh'
  } else if (cartUnit1.cartUnitType === 'ExpressShop') {
    return !!(
      cartUnit2.cartUnitType === 'ExpressShop' &&
      cartUnit1.expressShopBranch &&
      cartUnit2.expressShopBranch &&
      cartUnit1.expressShopBranch.branchCode ===
        cartUnit2.expressShopBranch.branchCode
    )
  } else {
    return cartUnit1.seller.sellerKey === cartUnit2.seller.sellerKey
  }
}

/**
 * shallowEqual 필수
 * 그룹별 배송비 노출조건 포함한 배송비정책 getShippingPolicyEx > getShippingPolicy > getShippingPolicyBasicInfo (음..너무 구린데..)
 * @param state
 * @param shippingPolicyKey
 */
export const getShippingPolicyEx = (
  state: RootState,
  shippingPolicyKey: string,
): ShippingPolicyEx | undefined => {
  const cartUnitList = state.cart.cartUnitList.filter(
    (cartUnit) => cartUnit.shippingPolicyKey === shippingPolicyKey,
  )
  if (cartUnitList.length === 0) {
    return undefined
  }

  const shippingPolicy = fromShipping.getShippingPolicy(
    state.shipping,
    shippingPolicyKey,
    cartUnitList[0].item.isECoupon,
  )

  if (!shippingPolicy) {
    return undefined
  }

  const {
    isQuantityShippingFee,
    isConditionalFree,
    shippingFee,
    isSpecialShipping,
  } = shippingPolicy

  const cartUnitListInSameMiniShop = state.cart.cartUnitList.filter(
    (cartUnit) => isSameMiniShop(cartUnit, cartUnitList[0]),
  )
  const itemCountInSameMiniShop = uniq(
    cartUnitListInSameMiniShop.map((_) => _.item.itemNo),
  ).length
  const shippingGroupCountInSameMiniShop = uniq(
    cartUnitListInSameMiniShop.map((_) => _.shippingPolicyKey),
  ).length
  const isEveryBuyUnavailable = every(
    cartUnitList,
    (cartUnit) => !cartUnit.isBuyAvailable,
  )
  const isExpressShop = every(
    cartUnitList,
    (cartUnit) => cartUnit.cartUnitType === 'ExpressShop',
  )
  const selectedCartUnitList = getSelectedCartUnitList(state).filter(
    (cartUnit) => cartUnit.shippingPolicyKey === shippingPolicyKey,
  )
  const overseaShippingInfo = selectedCartUnitList.reduce(
    (result: OverseaShippingInfo, cartUnit) => {
      const cartUnitOverseaShippingCostAndWeight =
        getCartUnitOverseaShippingCostAndWeight(
          state.shipping,
          cartUnit.cartUnitId,
        )

      if (!cartUnitOverseaShippingCostAndWeight) {
        return result
      }

      return {
        shippingFee:
          result.shippingFee + cartUnitOverseaShippingCostAndWeight.shippingFee,
        shippingWeight: parseFloat(
          (
            result.shippingWeight +
            cartUnitOverseaShippingCostAndWeight.shippingWeight
          ).toFixed(3),
        ),
      }
    },
    {
      shippingFee: 0,
      shippingWeight: 0,
    },
  )

  /**
   * 그룹별 배송비 노출조건
   * 1. 전부 주문 불가면 무조건 비노출
   * 2. 특수배송이면 무조건 비노출(홈플러스는 직접배송이지만 노출한다)
   * 3. 특수배송 아닌경우
   *   3.1. 무료배송이 아닌경우 노출
   *   3.2. 무료배송인경우
   *     3.2.1. 조건부 배송 혹은 수량별 배송비인 경우
   *     3.2.2. 미니샵 내에 uniq한 상품이 1개 초과인 경우
   *     3.2.3. 미니샵 내에 uniq한 배송그룹이 1개 초과인 경우
   */
  const isDisplayShippingFee = isEveryBuyUnavailable
    ? false
    : isSpecialShipping && !isExpressShop
    ? false
    : shippingFee > 0
    ? true
    : isConditionalFree ||
      isQuantityShippingFee ||
      itemCountInSameMiniShop > 1 ||
      shippingGroupCountInSameMiniShop > 1 ||
      overseaShippingInfo.shippingFee > 0 ||
      overseaShippingInfo.shippingWeight > 0

  return {
    ...shippingPolicy,
    isDisplayShippingFee,
    overseaShippingInfo,
  }
}

export const getIsShowingCashbackBenefit = (state: RootState): boolean => {
  const cashbackSummary = getTotalCashbackSummary(state)
  return (
    state.view.languageType === 'Korean' &&
    !state.buyer.isSimpleJoinForeigner &&
    state.buyer.memberType !== 'NonMember' &&
    (cashbackSummary.totalCashbackAmount > 0 ||
      cashbackSummary.appliedSsgPointCashback > 0)
  )
}

export const getDeliveryUnitCouponErrorType = (
  state: RootState,
  cartUnitId: number,
): DeliveryCouponErrorType | undefined => {
  const appliedUnitCouponList = fromUnitCoupon.getAppliedUnitCouponList(
    state.unitCoupon,
    cartUnitId,
  )
  if (appliedUnitCouponList.some((x) => x.isDeliveryCoupon)) {
    const cartUnit = fromCart.getCartUnitById(state.cart, cartUnitId)
    if (cartUnit) {
      const bundleShippingFee = fromShipping.getBundleShippingFee(
        state.shipping,
        cartUnit.bundleKey || '',
      )
      const shippingPolicy = fromShipping.getShippingPolicy(
        state.shipping,
        cartUnit.shippingPolicyKey,
        cartUnit.item.isECoupon,
      )

      if (shippingPolicy?.isSpecialShipping) {
        return 'SPECIAL_SHIPPING'
      }
      if (bundleShippingFee === 0) {
        return 'FREE_SHIPPING'
      }

      // TODO 동일 배송비그룹에 중복적용 안되는것으로 아는데, 기존 코드에 없다. 확인 후 살리거나 제거할것
      // const selectedSameShippingGroupCartUnitIds = fromCart.getSameShippingGroupCartUnitIds(
      //   state.cart,
      //   cartUnitId,
      //   true,
      // )
      //
      // if (
      //   selectedSameShippingGroupCartUnitIds.some((id) =>
      //     fromUnitCoupon
      //       .getAppliedUnitCouponList(state.unitCoupon, id)
      //       .some((x) => x.isDeliveryCoupon),
      //   )
      // ) {
      //   return 'DUPLICATED'
      // }
    }
  }
  return undefined
}

export const getSelectedShippingUnavailableCartUnitCount = (
  state: RootState,
): number => {
  const selectedCartUnits = getSelectedCartUnitList(state)
  return selectedCartUnits.filter((x) =>
    fromShipping.getIsCurrentShippingUnavailable(state.shipping, x.cartUnitId),
  ).length
}

export const getCurrentBtSeedItemInfo = (
  state: RootState,
):
  | {
      itemNo: string
      itemName: string
      imageUrl: string
    }
  | undefined => {
  const itemNo = fromRecommend.getCurrentBtSeedItemNo(state.recommend)
  if (itemNo) {
    const cartUnit = state.cart.cartUnitList.find(
      (x) => x.item.itemNo === itemNo,
    )
    if (cartUnit) {
      return {
        itemNo: cartUnit.item.itemNo,
        itemName: cartUnit.item.itemName,
        imageUrl: cartUnit.item.itemImageUrl || '',
      }
    }
  }
}

export const getSelectedCartUnitItemGroupListForEstimateSheet = (
  state: RootState,
): {
  cartUnitList: (CartUnit & { price: CartUnitPriceType })[]
  itemGroupSummary: ItemGroupSummaryType
}[] => {
  const selectedCartUnitList = getSelectedCartUnitList(state)

  return state.cart.cartItemTreeView.sellerGroups.flatMap((sellerGroup) =>
    sellerGroup.shippingGroups.flatMap((shippingGroup) =>
      shippingGroup.itemGroups.flatMap((itemGroup) => {
        const selected = selectedCartUnitList.filter(
          (x) =>
            x.isBuyAvailable && itemGroup.cartUnitIdList.includes(x.cartUnitId),
        )
        if (selected.length > 0) {
          return [
            {
              cartUnitList: selected.map((cartUnit) => {
                return {
                  ...cartUnit,
                  price: getCartUnitPriceInfo(state, cartUnit.cartUnitId),
                }
              }),
              itemGroupSummary: getItemGroupSummary(
                state,
                itemGroup,
                selected.map((x) => x.cartUnitId),
              ),
            },
          ]
        } else {
          return []
        }
      }),
    ),
  )
}

export const getIsAllSelected = (
  state: RootState,
  cartUnitIdList?: number[],
): boolean => {
  const targetCartUnitList = fromCart
    .getCurrentCartUnitList(state.cart)
    .filter(
      (cartUnit) =>
        !cartUnitIdList || cartUnitIdList.includes(cartUnit.cartUnitId),
    )

  const buyAvailableCartUnits = targetCartUnitList.filter(
    (cartUnit) => !getBuyUnavailableInfo(state, cartUnit.cartUnitId),
  )

  return (
    buyAvailableCartUnits.length > 0
      ? buyAvailableCartUnits
      : targetCartUnitList
  ).every((cartUnit) =>
    state.cart.selectedCartUnitIdList.includes(cartUnit.cartUnitId),
  )
}

export const getIsAvailableShippingCompanyNotExists = (
  state: RootState,
): boolean => {
  return (
    fromShipping.getIsOverseaShipping(state.shipping) &&
    getSelectedCartUnitShippingWeight(state) > 0 &&
    fromShipping.getOverseaShippingCompanyCount(state.shipping) === 0
  )
}

export const getSignalTooltipTitle = (
  isCouponApplied: boolean,
  issuedCoupon: number[] | undefined,
  recommendedCoupons: RecommendedUnitCouponNo[] | undefined,
): string | undefined => {
  // 쿠폰적용x && 쿠폰 다 받음 (받을 수 있는 쿠폰 Y || (받을 수 있는 쿠폰 N && 받은 쿠폰 유무 Y))
  if (
    !isCouponApplied &&
    (recommendedCoupons || (issuedCoupon && issuedCoupon.length > 0))
  ) {
    return '쿠폰을 적용해보세요!'
  }

  // 쿠폰적용o && 쿠폰 다 안받음
  if (isCouponApplied && recommendedCoupons) {
    return '더 싸게 살 수 있는 쿠폰이 있어요.'
  }

  // 쿠폰적용o && 쿠폰 다 받음
  // 쿠폰받은것도 없고 받을것도 없음
  return undefined
}
