import { uniq } from 'lodash'
import {
  ExchangeType,
  OverseaShippingCompanyType,
  ShippingCountryType,
} from '~/types/enums'

export const GlobalLanguageCodeAlias = 'Global'

/**
 * 쿠폰사용시 사용하지 않는 항목 키
 */
export const NotUseCouponDummyIssueNo = 0

/**
 * 다운로드 되지 않은 쿠폰발급번호
 */
export const NotDownloadedCouponIssueNo = -1

/**
 * PCS 가짜(fake) 쿠폰발급번호
 */
export const PcsFakeCouponIssueNo = -2

/**
 * 적용안함 항목 키
 */
export const UnapplyCouponDummyIssueNo = -3

/**
 * 아이템 할인 항목 키
 */
export const ItemDiscountCouponDummyIssueNo = -4

export const ElementIds = {
  CouponButton: 'xo_coupon_button_',
  CouponSubmitButton: 'xo_coupon_submit_btn_',
  CartUnit: 'xo_cart_unit_',
  AddNewAddress: 'xo_id_add_new_address',
  ReceiverName: 'xo_id_receiver_name',
  ReceiverPhoneNumber: 'xo_id_receiver_phone_number',
  OpenAddressBook: 'xo_id_open_address_book',
  FindAddress: 'xo_id_find_address',
  ZipCode: 'xo_id_zip_code',
  Address2: 'xo_id_address_2',
  ShippingRequest: 'xo_id_shipping_request',
  BuyerName: 'xo_id_buyer_name',
  BuyerPhoneNumber: 'xo_id_buyer_phone_number',
  BuyerEmail: 'xo_id_buyer_email',
  NonMemberPassword: 'xo_id_non_member_password',
  NonMemberPasswordConfirm: 'xo_id_non_member_password_confirm',
  EcouponSenderName: 'xo_id_ecoupon_sender_name',
  EcouponSenderPhoneNumber: 'xo_id_ecoupon_sender_phone_number',
  EcouponReceiverName: 'xo_id_ecoupon_receiver_name',
  EcouponReceiverPhoneNumber: 'xo_id_ecoupon_receiver_phone_number',
  EcouponAuthPhone: 'xo_id_ecoupon_auth_phone',
  AgreementsCollectingPersonalInfo: 'xo_id_agreements_collecting_personal_info',
  AgreementsProvidingPersonalInfo: 'xo_id_agreements_providing_personal_info',
  AgreementsEbayBuyer: 'xo_id_agreements_ebay_buyer',
  AgreementsETrade: 'xo_id_agreements_e_trade',
  AgreementsCustomsClearance: 'xo_id_agreements_customs_clearance',
  AgreementsProvidingCustomsClearance:
    'xo_id_agreements_providing_customs_clearance',
  AgreementsTaxId: 'xo_id_agreements_tax_id',
  AgreementsTaiwanEntry: 'xo_id_agreements_taiwan_entry',
  AgreementsTaiwanSeparateDeclaration:
    'xo_id_agreements_taiwan_separate_declaration',
  CustomsClearanceValue: 'xo_id_customs_clearance_value',
  TaxIdValue: 'xo_id_tax_id_value',
  TaiwanNameCert: 'xo_id_taiwan_name_cert',
  RadioButtonLargeMethodNormal: 'xo_id_radio_button_large_method_normal',
  RadioButtonLargeMethodSmilePay: 'xo_id_radio_button_large_method_smile_pay',
  SectionSmilePay: 'xo_id_section_smile_pay',
  SectionNormalPay: 'xo_id_section_normal_pay',
  CashReceiptPhone1: 'xo_id_cash_receipt_phone_1',
  CashReceiptPhone2: 'xo_id_cash_receipt_phone_2',
  CashReceiptPhone3: 'xo_id_cash_receipt_phone_3',
  CashReceiptCard1: 'xo_id_cash_receipt_card_1',
  CashReceiptCard2: 'xo_id_cash_receipt_card_2',
  CashReceiptCard3: 'xo_id_cash_receipt_card_3',
  CashReceiptCard4: 'xo_id_cash_receipt_card_4',
  CashReceiptCorpNumber1: 'xo_id_cash_receipt_corp_number_1',
  CashReceiptCorpNumber2: 'xo_id_cash_receipt_corp_number_2',
  CashReceiptCorpNumber3: 'xo_id_cash_receipt_corp_number_3',
  EPrepayReceiptCorpNumber1: 'xo_id_e_prepay_receipt_corp_number_1',
  EPrepayReceiptCorpNumber2: 'xo_id_e_prepay_receipt_corp_number_2',
  EPrepayReceiptCorpNumber3: 'xo_id_e_prepay_receipt_corp_number_3',
  RefundAccountBank: 'xo_id_refund_account_bank',
  RefundAccountNumber: 'xo_id_refund_account_number',
  RefundAccountOwnerName: 'xo_id_refund_account_owner_name',
  RefundAccountConfirmButton: 'xo_id_refund_account_confirm_button',
  CashReceiptConfirmButton: 'xo_id_cash_receipt_confirm_button',
  EPrepayReceiptConfirmButton: 'xo_id_e_prepay_receipt_confirm_button',
  SmileCashInput: 'xo_id_smile_cash_input',
  SmilePointInput: 'xo_id_smile_point_input',
  DetailMethodSelector: 'xo_id_detail_method_selector',
  ForeignCardNumber: 'xo_id_foreign_card_number',
  ForeignCardMonthYear: 'xo_id_foreign_card_month_year',
  ForeignCardCvc: 'xo_id_foreign_card_cvc',
  ChangeCountryWhenOversea: 'xo_id_change_country_when_oversea',
}

export const EmailDomains: { id: number; value: string; text: string }[] = [
  { id: 0, value: '', text: '직접입력' },
  { id: 1, value: 'naver.com', text: 'naver.com' },
  { id: 2, value: 'daum.net', text: 'daum.net' },
  { id: 3, value: 'hotmail.com', text: 'hotmail.com' },
  { id: 4, value: 'nate.com', text: 'nate.com' },
  { id: 5, value: 'yahoo.co.kr', text: 'yahoo.co.kr' },
  { id: 6, value: 'paran.com', text: 'paran.com' },
  { id: 7, value: 'empas.com', text: 'empas.com' },
  { id: 8, value: 'dreamwiz.com', text: 'dreamwiz.com' },
  { id: 9, value: 'freechal.com', text: 'freechal.com' },
  { id: 10, value: 'lycos.co.kr', text: 'lycos.co.kr' },
  { id: 11, value: 'korea.com', text: 'korea.com' },
  { id: 12, value: 'gmail.com', text: 'gmail.com' },
  { id: 13, value: 'hanmir.com', text: 'hanmir.com' },
]

export const AvailableExchangeTypes: ExchangeType[] = [
  'AED',
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'EUR',
  'GBP',
  'HKD',
  'JPY',
  'KZT',
  'MNT',
  'MOP',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'RUB',
  'SAR',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'USD',
  // 'VND',
  'KRW',
]

export const ExchangeSymbols: PartialRecord<ExchangeType, string> = {
  AED: 'Dh',
  AUD: 'A$',
  BRL: 'R$',
  CAD: 'C$',
  CNY: '￥',
  EUR: '€',
  GBP: '￡',
  HKD: 'H$',
  JPY: '￥',
  KZT: 'KZT',
  MNT: '₮',
  MOP: 'MOP$',
  MYR: 'RM',
  NOK: 'Nkr',
  NZD: 'NZ$',
  PHP: '₱',
  RUB: 'pуб',
  SAR: 'SR',
  SGD: 'S$',
  THB: '฿',
  TRY: 'TL',
  TWD: 'NT$',
  USD: '$',
  VND: '₫',
  KRW: '₩',
}

export const AggregatorShippingCompanies: OverseaShippingCompanyType[] = [
  'Aggregator',
  'Aggregator2',
  'Aggregator3',
  'Aggregator4',
  'Aggregator5',
]

export const AggregatorPriceLimits: PartialRecord<ShippingCountryType, number> =
  {
    Indonesia: 85000,
    Thailand: 135000,
    Japan: 150000,
    Philippines: 220000,
    MalaysiaWest: 230000,
    RussianFederation: 230000,
    Taiwan: 230000,
    NewZealand: 270000,
    Singapore: 320000,
    HongKong: 580000,
    Australia: 690000,
    UnitedStates: 870000,
  }

export const SmileDeliveryConditionalFreeBasisCost = 15000 // 통합멤버십 도임으로 더 이상 쓰이지 않는 값
export const SmileFreshConditionalFreeBasisCost = 40000

export const SmilePayApiConsts = {
  SmilePayUserType: {
    Unknown: 0,
    SmilePayMember: 1,
    SmilePayNonMemberWithMigreation: 2,
    SmilePayNonMember: 3,
    SmilePayMemberNonPayment: 4,
  },
  UserType: {
    Unknown: 0,
    Personal: 1,
    Company: 2,
    Foreigner: 3,
    GmarketNonMember: 4,
  },
}

/**
 * id 중복체크..
 * 더 아름다운방법은 없을까?
 */
const elementIdValues = Object.values(ElementIds)
if (elementIdValues.length !== uniq(elementIdValues).length) {
  const duplicatedList = elementIdValues.reduce<string[]>((result, value) => {
    if (
      !result.includes(value) &&
      elementIdValues.filter((x) => x === value).length > 1
    ) {
      return result.concat(value)
    } else {
      return result
    }
  }, [])
  throw new Error('Some element id is duplicated\n' + duplicatedList.join('\n'))
}
