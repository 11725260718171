import moment from 'moment'
import React, { CSSProperties } from 'react'
import isEqual from 'react-fast-compare'
import AutoCouponNotification from '~/cart/gmarket/ko/common/components/CouponBox/AutoCouponNotification'
import ClubNudgingInsertCouponNotification from '~/cart/gmarket/ko/common/components/CouponBox/ClubNudgingInsertCouponNotification'
import CouponStateDisplay from '~/cart/gmarket/ko/common/components/CouponBox/CouponStateDisplay'
import {
  CouponBoxTableItemViewData,
  CouponBoxTableType,
} from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import { NotDownloadedCouponIssueNo } from '~/data/consts'
import { currencyAmount, numberMixKoreanCurrencyAmount } from '~/lib/formatter'

type CouponBoxTableItemProps = {
  tableType: CouponBoxTableType
  id: string
  viewData: CouponBoxTableItemViewData
  isSelected: boolean
  isSelectedAuto: boolean
  isExpandView: boolean
  isExpandHead: boolean
  isExpanded: boolean
  onClickExpandButton: (
    couponPolicyNo?: number,
  ) => React.MouseEventHandler<HTMLElement>
  onChangeCoupon: (
    key: CouponBoxTableType,
    couponIssueNo: number,
  ) => React.ChangeEventHandler<HTMLInputElement>
  onClickJoinSmileClub: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const CouponBoxTableItem = ({
  tableType,
  id,
  viewData,
  isSelected,
  isSelectedAuto,
  isExpandView,
  isExpandHead,
  isExpanded,
  onClickExpandButton,
  onChangeCoupon,
  onClickJoinSmileClub,
}: CouponBoxTableItemProps): JSX.Element => {
  if (viewData.isNotUseCoupon) return <></>

  if (!viewData.isFakeCoupon) {
    const getExpandTailCustomCss = (): CSSProperties | undefined => {
      if (!isExpandView) return undefined
      if (isExpandHead) return undefined
      if (isExpanded) return { display: 'block' }
      else return { display: 'none' }
    }

    return (
      <li
        className={`list-item${
          (viewData.isClubNudgingInsertCoupon ? ' list-item__nonclub' : '') +
          (isExpandView ? ' list-item--duplicate' : '') +
          (isExpandView && isExpandHead && isExpanded ? ' js-expand' : '')
        }`}
        style={getExpandTailCustomCss()}
      >
        <div className="box__title">
          <div className="box__form-radio">
            <CouponStateDisplay
              pending={viewData.isDownloading}
              failure={viewData.issueNo === NotDownloadedCouponIssueNo}
              autoSelect={isSelectedAuto}
            >
              <input
                type="radio"
                className={`form__radio ${
                  viewData.isSmileclubCoupon ? 'form__club' : ''
                }`}
                id={id}
                checked={isSelected}
                onChange={onChangeCoupon(tableType, viewData.issueNo)}
                data-montelena-acode={
                  viewData.isClubNudgingInsertCoupon
                    ? areaCodes.SELECT_CLUB_NUDGING_INSERT_COUPON
                    : areaCodes.SELECT_COUPON
                }
                data-montelena-buyer_coupon_master_no_1={
                  viewData.isClubNudgingInsertCoupon &&
                  tableType === CouponBoxTableType.Buyer &&
                  viewData.policyNo
                    ? viewData.policyNo
                    : ''
                }
                data-montelena-super_coupon_master_no_2={
                  viewData.isClubNudgingInsertCoupon &&
                  tableType === CouponBoxTableType.Super &&
                  viewData.policyNo
                    ? viewData.policyNo
                    : ''
                }
              />
            </CouponStateDisplay>
            <label htmlFor={id} className="form__label sprite__coupon--before">
              {viewData.isDeliveryCoupon ? (
                <span className="format-price">
                  <span className="box__format-amount">
                    <strong className="text__value">배송비</strong>
                  </span>
                </span>
              ) : (
                <span>
                  {currencyAmount(viewData.price)}
                  <span className="text__unit">원</span>
                </span>
              )}
            </label>
          </div>
        </div>
        <div className="box__information">
          <span className="text__information">
            <label htmlFor={id}>
              {viewData.description ? (
                <span className="label">
                  {viewData.isSmileclubCoupon && !viewData.isBizCoupon && (
                    <span className="sprite__coupon icon__club">
                      <span className="for-a11y">유니버스 클럽</span>
                    </span>
                  )}
                  {viewData.isBizCoupon && (
                    <span className="sprite__coupon business-club">
                      <span className="for-a11y">사업자클럽</span>
                    </span>
                  )}
                  {viewData.isAppCoupon && (
                    <span className="sprite__coupon app">
                      <span className="for-a11y">앱전용</span>
                    </span>
                  )}
                  {viewData.isMobileCoupon && (
                    <span className="sprite__coupon mobile">
                      <span className="for-a11y">모바일 전용</span>
                    </span>
                  )}
                  {viewData.description}
                </span>
              ) : (
                <span className="label">G마켓 추가 할인</span>
              )}
            </label>

            {!viewData.isDiscount && viewData.expiredDate && (
              <span className="text__expire-day">
                {viewData.possiblePrice && viewData.possiblePrice > 0
                  ? `${numberMixKoreanCurrencyAmount(
                      viewData.possiblePrice,
                    )}원 이상 구매시 `
                  : ''}

                {viewData.discountUnitType !== 'Rate' ||
                viewData.limitPrice === undefined
                  ? ''
                  : viewData.limitPrice === 0
                  ? '무제한 할인 '
                  : `최대 ${numberMixKoreanCurrencyAmount(
                      viewData.limitPrice,
                    )}원 `}

                {`(~${moment(viewData.expiredDate).format('MM/DD')})`}
              </span>
            )}
          </span>
          {isExpandHead && (
            <button
              type="button"
              className="sprite button__expand"
              aria-expanded={isExpanded}
              data-montelena-acode={
                isExpanded
                  ? areaCodes.COUPON_BOX_EXPAND_CLOSE
                  : areaCodes.COUPON_BOX_EXPAND_OPEN
              }
              onClick={onClickExpandButton(viewData.policyNo)}
            >
              <span className="for-a11y">같은 쿠폰 더보기</span>
            </button>
          )}
          <p>
            {viewData.isAppliedOnAnother && (
              <button className="text__dec" disabled={true}>
                {viewData.appliedOnAnotherPrice === undefined ||
                viewData.appliedOnAnotherPrice === 0
                  ? '적용중'
                  : `다른 상품에 ${currencyAmount(
                      viewData.appliedOnAnotherPrice,
                      false,
                    )}원 적용중`}
              </button>
            )}
          </p>
          {viewData.isAutoDownloadedCoupon && (
            <AutoCouponNotification
              couponType={tableType}
              couponPolicyNo={viewData.policyNo}
            />
          )}
          {viewData.isClubNudgingInsertCoupon && (
            <ClubNudgingInsertCouponNotification
              couponType={tableType}
              couponPolicyNo={viewData.policyNo}
            />
          )}
        </div>
        {viewData.isClubNudgingInsertCoupon && (
          <div className="box__club-nudging">
            <button
              type="button"
              onClick={onClickJoinSmileClub}
              className="button__join"
              data-montelena-acode={
                areaCodes.CLICK_JOIN_CLUB_NUDGING_BANNER_ON_INSERT_COUPON
              }
              data-montelena-buyer_coupon_master_no_1={
                tableType === CouponBoxTableType.Buyer && viewData.policyNo
                  ? viewData.policyNo
                  : ''
              }
              data-montelena-super_coupon_master_no_2={
                tableType === CouponBoxTableType.Super && viewData.policyNo
                  ? viewData.policyNo
                  : ''
              }
            >
              멤버십 가입하고 쿠폰 적용하기
            </button>
            <p className="text sprite__coupon--after">
              💰바로 쓸 수 있는{' '}
              <span className="text__emphasis">
                {!viewData.clubWelcomeGift
                  ? '스마일캐시'
                  : `${currencyAmount(viewData.clubWelcomeGift)}원`}
              </span>
              도 추가로 받으세요!
            </p>
          </div>
        )}
      </li>
    )
  } else {
    return (
      <li className="list-item">
        <div className="box__title">
          <div className="box__form-radio">
            <input
              type="radio"
              className="form__radio"
              id={id}
              checked={isSelected}
              onChange={onChangeCoupon(tableType, viewData.issueNo)}
              data-montelena-acode={viewData.areaCode}
            />
            <label htmlFor={id} className="form__label sprite__coupon--before">
              <span className="format-price">
                <span className="box__format-amount">
                  <strong className="text__value">
                    {currencyAmount(
                      viewData && !isNaN(Number(viewData.text))
                        ? Number(viewData.text)
                        : 0,
                    )}
                  </strong>
                  <span className="text__unit">{viewData.unit}</span>
                </span>
              </span>
            </label>
          </div>
        </div>
        <div className="box__information">
          <span className="text__information">
            <label htmlFor={id}>
              <span className="label">
                {viewData.description}{' '}
                {viewData.subDescription && `(${viewData.subDescription})`}
              </span>
            </label>
          </span>
        </div>
      </li>
    )
  }
}

export default React.memo(CouponBoxTableItem, isEqual)
