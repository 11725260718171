import React from 'react'

type CouponStatefulDisplayProps = {
  pending?: boolean
  failure?: boolean
  autoSelect?: boolean
  children: React.ReactNode
}

const CouponStateDisplay = ({
  pending = false,
  failure = false,
  autoSelect = false,
  children,
}: CouponStatefulDisplayProps): JSX.Element => {
  if (pending) {
    return (
      <span className="image__loading dot_mix_24" aria-label="로딩중"></span>
    )
  }

  if (failure) {
    return (
      <span
        className="image__fail sprite__coupon"
        aria-label="다운로드 실패"
      ></span>
    )
  }

  if (autoSelect) {
    return (
      <>
        <span
          className="image__disabled sprite__coupon"
          aria-label="선택 불가" // 선택 불가 보다는 자동 선택에 가까우나, 퍼블리싱 따라감
        ></span>
        {children}
      </>
    )
  }

  return <>{children}</>
}

export default CouponStateDisplay
